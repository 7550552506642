import React from "react";
import TextCustom from "../atoms/Text";
import { Divider, Flex, Image } from "antd";
import search from "../../Assets/images/tournament/icon/search.png";
import arrowLeft from "../../Assets/images/tournament/icon/arrow-left.png";
import arrowLeftWhite from "../../Assets/images/tournament/icon/arrow-left-white.png";
import colors from "../../utils/colors";
import { useNavigate } from "react-router-dom";

export default function HeaderMobile({
  isTransparent,
  title,
  isSearch = true,
  onClickSearch,
}) {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        style={{
          backgroundColor: colors.white,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Flex
          align="center"
          style={{
            backgroundColor: colors.white,
          }}
        >
          <Image
            onClick={() => navigate(-1)}
            preview={false}
            width={20}
            src={arrowLeft}
          />

          <TextCustom
            fontSize={16}
            fontWeight="bold"
            color={colors.black}
            text={title}
          />
        </Flex>
        {isSearch && (
          <Image
            onClick={onClickSearch}
            preview={false}
            width={25}
            src={search}
          />
        )}
      </Flex>
    </>
  );
}
