import { Flex, Image } from "antd";
import React from "react";
import people from "../../Assets/images/tournament/icon/people.png";
import cup from "../../Assets/images/tournament/icon/cup.png";
import notif from "../../Assets/images/tournament/icon/notif.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import TextCustom from "../../components/atoms/Text";
import colors from "../../utils/colors";
import { Navigate, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import convertToRupiah from "../../utils/convertToRupiah.js";

export default function ItemListTournamentLive({ item, id, index }) {
  const navigate = useNavigate();
  const statusTournaments = [
    {
      label: "Dibuka",
      value: "1",
    },
    {
      label: "Full",
      value: "2",
    },
    {
      label: "Tutup",
      value: "3",
    },
  ];

  const filterStatusFilter = () => {
    let data = statusTournaments.filter((e) => {
      return item.status == e.value;
    });
    return data[0]?.label;
  };

  return (
    <Flex
      vertical
      wrap
      style={{
        backgroundColor: colors.secondary1,
        width: 300,
        borderRadius: 10,
        marginRight: 10,
      }}
    >
      <Image
        width={"100%"}
        height={150}
        src={item?.banner}
        style={{ width: "100%", height: 150, objectFit: "cover" }}
      />
      <Flex
        vertical
        style={{ margin: 10, textWrap: "wrap", flexWrap: "wrap" }}
        onClick={() =>
          navigate(`/room-tournament/${id}`, {
            state: item,
          })
        }
      >
        <TextCustom
          fontWeight="bold"
          fontSize={14}
          color={colors.black}
          text={item?.nama_kategori_game}
        />
        <TextCustom
          text={`${item?.nama_kategori_game} |  ${dayjs(
            item?.start_date,
          ).format("DD MMMM")} -  ${dayjs(item?.end_date).format(
            "DD MMMM YYYY",
          )}`}
        />
        <Flex gap="small">
          <Flex align="center" gap={2} justify="center">
            <Image preview={false} width={15} src={people} />
            <TextCustom fontSize={10} text={item?.total_peserta} />
          </Flex>
          <Flex align="center" gap={2} justify="center">
            <Image preview={false} width={15} src={cup} />
            <TextCustom
              fontSize={10}
              text={convertToRupiah(item?.registration_fee)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
