import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
} from "antd";
import React, { useState } from "react";
// import edit from "../../../Assets/images/tournament/icon/edit.png";
import star from "../../../Assets/images/tournament/icon/star.png";
import medali from "../../../Assets/images/tournament/icon/medali.png";
import arrowLeftWhite from "../../../Assets/images/tournament/icon/arrow-left-white.png";
import tournament from "../../../Assets/images/tournament/tournament.png";
import ButtonCustom from "../../../components/atoms/Button";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemPlayer from "../../../components/ListItemPlayer";
import colors from "../../../utils/colors";

import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import Constant from "../../../config/constans";
import axios from "axios";
import { useEffect } from "react";
import ListItemMember from "../../../components/ListItemMember";
import ListItemTournament from "../../../components/ListItemTournament";
import { range, uniq } from "lodash";
import { useTranslation } from "react-i18next";

const LandingPagesDetailPlayer = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectCaptain, setSelectCaptain] = useState("");
  const { dataState } = useLocation()?.state;
  const authToken = localStorage.getItem("token");
  const idUser = localStorage.getItem("idUserLogin");
  const [messageApi, contextHolder] = message.useMessage();
  const [formChange] = Form.useForm();
  const [dataUser, setDataUser] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [dataDetailPlayer, setDataDetailPlayer] = useState({});
  const [deskripsiTeam, setDeskripsiTeam] = useState("");
  const [namaTeam, setNamaTeam] = useState("");
  const [options, setOptions] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [openModalCheckTeam, setOpenModalCheckTeam] = useState(false);
  const [dataModalCheckTeam, setDataModalCheckTeam] = useState({});
  const { t } = useTranslation();
  console.log("dataState LandingPagesDetailPlayer", dataState);

  const [selectTab, setSelectTab] = useState({});

  useEffect(() => {
    formChange.setFieldsValue({
      id_team: dataDetailPlayer?.id_team,
      badger: dataDetailPlayer?.badger,
      image_team: "-",
      deskripsi_team: dataDetailPlayer?.deskripsi_team,
      nama_team: dataDetailPlayer?.nama_team,
      id_kategori_game: dataDetailPlayer?.id_kategori_game,
    });
  }, [dataDetailPlayer]);

  const roleTeam = (id_role) => {
    let textRole = listRole.filter((item) => item?.id_role == id_role);
    return textRole[0]?.nama_role || "Player";
  };

  const getListRole = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ROLE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          let tmp = [];
          data?.map((item) => {
            tmp.push({ value: item?.nama_role, label: item?.id_role });
            if (item.label === dataUser.role) {
              setSelectedRole(item?.nama_role);
            }
          });

          setOptions(tmp);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleEditTeam = () => {
    let payload = {
      id_team: dataDetailPlayer?.id_team,
      badger: dataDetailPlayer?.badger,
      image_team: "-",
      deskripsi_team: deskripsiTeam,
      nama_team: namaTeam,
      id_kategori_game: dataDetailPlayer?.id_kategori_game,
      captain: selectCaptain,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_UPDATE_TEAM;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          setIsModalOpen(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const getDetailProfile = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_user: idUser,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TEAM_SAYA;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataUser(response.data.Data[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const getDetailPlayer = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TEAM_SAYA;
    axios
      .post(
        URL,
        {
          id_user: idUser,
          // id_user: "223b1ad5-dfae-4f0a-bc46-18b4338aab88",
        },
        { headers },
      )
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          setDataDetailPlayer(data);
          let tmp = [];
          data?.teams?.map((item) => {
            tmp.push({ label: item?.id_user, value: item?.nama });
            setSelectCaptain({
              id: item?.id_user,
              value: item?.nama,
            });

            if (item?.id_user == data?.captain) {
              formChange.setFieldsValue({
                captain: item?.nama,
              });
            }
          });
          setOptions(uniq(tmp));

          // setSelectTab({
          //   key: "1",
          //   label: "Teams",
          //   children: (
          //     <>
          //       {data?.teams?.map((item, index) => {
          //         return <ListItemMember item={item} type={"player"} />;
          //       })}
          //     </>

          //   ),
          // });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleDeleteTeam = (record) => {
    console.log("recordd", record);
    let payload = {
      id_team: record?.id_team,
      id_user_delete: idUser,
      id_kategori_game: record?.id_kategori_game,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_TEAM;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          messageApi.open({
            type: "success",
            content: "Berhasil menghapus anggota team",
            duration: 4,
          });
          setIsModalOpen(false);
          setOpenModalCheckTeam(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleAddTournament = async () => {
    setIsModalOpen(false);
  };

  const itemlistCard = [
    {
      title: "Total Tournament",
      value: dataDetailPlayer?.total_tournament,
    },
    {
      title: "Total Menang",
      value: dataDetailPlayer?.total_menang,
    },
    {
      title: "Total Kalah",
      value: dataDetailPlayer?.total_kalah,
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const itemsTab = [
    {
      key: "1",
      label: "Teams",
      children: (
        <>
          {dataDetailPlayer?.current_teams?.length ? (
            dataDetailPlayer?.current_teams?.map((item, index) => {
              return (
                <ListItemMember
                  item={item}
                  type={"trash"}
                  isFromMyTeam={true}
                  onClickIcon={() => {
                    setOpenModalCheckTeam(true);
                    setDataModalCheckTeam(item);
                  }}
                />
              );
            })
          ) : (
            <TextCustom
              fontSize={12}
              text={t("DataNotFound")}
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
        </>
      ),
    },
    {
      key: "2",
      label: "History Teams",
      children: (
        <>
          {dataDetailPlayer?.teams?.length ? (
            dataDetailPlayer?.teams?.map((item, index) => {
              return (
                <ListItemMember isFromMyTeam={true} item={item} type={""} />
              );
            })
          ) : (
            <TextCustom
              fontSize={12}
              text={t("DataNotFound")}
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "History Tournament",
      children: (
        <>
          {dataDetailPlayer?.tournaments?.length ? (
            dataDetailPlayer?.tournaments?.map((item, index) => {
              return <ListItemTournament item={item} type={"tournament"} />;
            })
          ) : (
            <TextCustom
              fontSize={12}
              text={t("DataNotFound")}
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    getDetailPlayer();
    // getDetailProfile();
    getListRole();
  }, []);

  useEffect(() => {
    setSelectTab({
      key: "1",
      label: "Teams",
      children: (
        <>
          {dataDetailPlayer?.current_teams?.length ? (
            dataDetailPlayer?.current_teams?.map((item, index) => {
              return (
                <ListItemMember
                  item={item}
                  type={"trash"}
                  isFromMyTeam={true}
                  onClickIcon={() => {
                    setOpenModalCheckTeam(true);
                    setDataModalCheckTeam(item);
                  }}
                />
              );
            })
          ) : (
            <TextCustom
              fontSize={12}
              text={t("DataNotFound")}
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
        </>
      ),
    });
  }, [dataDetailPlayer]);

  return (
    <LayoutMobile>
      {contextHolder}
      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
        }}
      >
        {/* Header */}
        <section style={{ position: "relative" }}>
          <section style={{ position: "relative", top: 0, zIndex: 8 }}>
            {/* <Image src={mobileLegendXL} style={{ width: "100%" }} /> */}
            <div
              className="card"
              style={{
                background: "linear-gradient(to right, #9D50BB, #6E48AA)",
                width: "100%",
                height: 300,
              }}
            ></div>

            <Flex
              vertical
              style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                padding: 20,
                paddingRight: 40,
              }}
            >
              <Image
                preview={false}
                src={dataDetailPlayer?.photo_profile}
                style={{ borderRadius: 100, height: 100, width: 100 }}
              />
              <Gap height={10} />

              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <TextCustom
                    fontWeight="bold"
                    fontSize={14}
                    color={colors.white}
                    text={dataDetailPlayer?.nama}
                  />
                  <Gap width={10} />
                  {range(dataDetailPlayer?.badge_royalty || 0).map((item) => (
                    <Image
                      preview={false}
                      src={medali}
                      style={{ height: 20, width: 20, marginLeft: 5 }}
                    />
                  ))}
                </Flex>
                <div>
                  {range(dataDetailPlayer?.star_player || 0).map((item) => (
                    <Image
                      preview={false}
                      src={star}
                      style={{ height: 20, width: 20, marginRight: 5 }}
                    />
                  ))}
                </div>
                {/* <Image
                  preview={false}
                  src={edit}
                  style={{ height: 30, width: 30 }}
                  onClick={showModal}
                /> */}
              </Flex>
              <TextCustom
                color={colors.white}
                text={dataDetailPlayer?.nickname}
              />
            </Flex>
          </section>
          <Flex
            justify="space-between"
            style={{
              padding: 20,
              position: "absolute",
              zIndex: 100,
              top: 0,
            }}
          >
            <Flex
              gap={"small"}
              align="center"
              style={{
                backgroundColor: "transparent",
                zIndex: 10,
                position: "relative",
              }}
            >
              <Image
                onClick={() => navigate(-1)}
                preview={false}
                width={20}
                src={arrowLeftWhite}
              />

              <TextCustom
                fontSize={16}
                fontWeight="bold"
                color={colors.white}
                text={t("DetailPlayer")}
              />
            </Flex>
            {/* <Image preview={false} width={30} src={search} /> */}
          </Flex>
        </section>

        <Layout
          style={{
            padding: 20,
            backgroundColor: colors.white,
          }}
        >
          <Flex
            style={{
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {itemlistCard.map((item, index) => {
              return (
                <Flex
                  vertical
                  wrap
                  style={{
                    backgroundColor: colors.secondary1,
                    width: 300,
                    borderRadius: 10,
                    margin: 10,
                    marginLeft: index == 0 ? 0 : 10,
                  }}
                >
                  <Flex vertical style={{ margin: 10 }}>
                    <TextCustom
                      fontSize={12}
                      color={colors.black}
                      fontWeight="bold"
                      text={item?.title}
                    />
                    <Flex vertical>
                      <Flex vertical>
                        <TextCustom
                          fontWeight="bold"
                          fontSize={18}
                          color={colors.primary2}
                          text={item?.value}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
          <Gap height={20} />

          <section>
            <Flex gap={"large"}>
              {itemsTab.map((item) => {
                return (
                  <div>
                    <section
                      onClick={() => setSelectTab(item)}
                      style={{
                        borderBottom:
                          selectTab.label == item.label
                            ? `3px solid ${colors.primary1}`
                            : "",
                      }}
                    >
                      <TextCustom
                        fontSize={14}
                        fontWeight="bold"
                        color={colors.black}
                        text={item.label}
                      />
                    </section>
                  </div>
                );
              })}
            </Flex>
            <Gap height={15} />

            <Flex
              vertical
              style={{
                width: "100%",
                overflowX: "scroll",
                height: "50vh",
              }}
            >
              {selectTab.children}
            </Flex>
          </section>

          <Gap height={20} />
        </Layout>
        <Modal
          onCancel={() => setIsModalOpen(false)}
          title="Edit Team"
          open={isModalOpen}
          footer={null}
        >
          <Gap height={30} />
          <Form
            form={formChange}
            onFinish={handleEditTeam}
            name="layout-multiple-vertical"
            layout="vertical"
            labelCol={{
              span: 100,
            }}
            wrapperCol={{
              span: 100,
            }}
          >
            <Form.Item
              label="Nama Team"
              name="nama_team"
              rules={[
                {
                  required: true,
                  message: "Silahkan Masukkan Nama Team",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Deskripsi Team"
              name="deskripsi_team"
              rules={[
                {
                  required: true,
                  message: "Silahkan Masukkan Deskripsi Team",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pilih Captain"
              name="captain"
              rules={[
                {
                  required: true,
                  message: "Silahkan Masukkan Captain Team",
                },
              ]}
            >
              <AutoComplete
                options={options}
                optionRender={(e) => (
                  <div
                    onClick={() =>
                      setSelectCaptain({
                        id: e?.value,
                        value: e?.label,
                      })
                    }
                  >
                    {e?.value}
                  </div>
                )}
                placeholder="Pilih captain"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
            <Gap height={20} />

            {selectedRole == "Manager" && (
              <ButtonCustom htmlType={"submit"} text={"Buat Team"} />
            )}
          </Form>
        </Modal>
        <Modal
          title=""
          closable={false}
          open={openModalCheckTeam}
          // onCancel={() => setIsModalSearch(false)}
          footer={null}
          style={{
            overflowX: "scroll",
            height: "70%",
          }}
          // wrapStyle={{ height: window.innerHeight - 200,  }}
        >
          <Flex vertical style={{ textAlign: "center" }}>
            <Gap height={20} />
            <TextCustom
              fontWeight="bold"
              fontSize={14}
              color={colors.black}
              text={t("ApakahAndaYakinUntukMenghapusSebagaiMemberAnda", {
                name: dataModalCheckTeam?.nama_team,
              })}
            />
          </Flex>
          <Gap height={40} />
          <Flex gap={"large"}>
            <ButtonCustom
              onClick={() => handleDeleteTeam(dataModalCheckTeam)}
              text={t("Yes")}
              backgroundColor={colors.primary2}
            />
            <ButtonCustom
              onClick={() => setOpenModalCheckTeam(false)}
              text={t("No")}
              backgroundColor={colors.red}
            />
          </Flex>
        </Modal>
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesDetailPlayer;
