import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import './index.scss';
import { LeftOutlined } from '@ant-design/icons';
import Constant from "../../../config/constans";
import ReactPlayer from 'react-player';
import { Link, useLocation } from "react-router-dom";
import { Empty, Typography, Card, Flex, Image, Divider } from 'antd';
const { Text, Title } = Typography;

const LandingPagesHighlightsDetail = () => {
  const [dataVideo, setDataVideo] = useState(null);
  const location = useLocation();
  const itemState = location.state.item;

  useEffect(() => {
    getListVideo();
  }, []);

  const getListVideo = () => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_VIDEO;
    axios
      .get(URL)
      .then((response) => {
        if (response.status === 200) {
          setDataVideo(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Link to={`/highlights`} className="web-content-button-back"><LeftOutlined />
        <Text>Kembali</Text>
      </Link>
      <Flex vertical justify="center" align="center">
      {itemState ? (
          <Flex vertical className="web-content-highlights-detail">
            <Title level={1} className="web-content-highlights-detail-title">{itemState.nama_video}</Title>
            <Flex vertical className="web-content-highlights-detail-tags">
              <Text className="web-content-highlights-detail-tags-item">{moment(itemState.tanggal_video).format("DD MMM YYYY HH:mm")}</Text>
              <Text className="web-content-highlights-detail-tags-item">{itemState.nama_kategori_game} | {itemState.nama_sub_kategori_game}</Text>
            </Flex>
            <ReactPlayer url={itemState.url_video} controls width="100%" height="50vh" className="web-content-highlights-video" />
            <Text>{itemState.deskripsi}</Text>
            <Divider>
              <Title level={3} className="web-content-highlights-title-video-lainnya">Video Lainnya</Title>
            </Divider>
            <Flex className="web-content-highlights-video-lainnya">
              {
                dataVideo && dataVideo.map((item) => 
                  item.id_video !== itemState.id_video ?
                  (
                    <Link to={`/highlights/${item.id_video}`} state={{item}}>
                      <Card>
                        <Image preview={false} src={item.thumbnail_video} />
                        {item.nama_video}

                      </Card>
                    </Link>
                  ) : null
                )
              }
            </Flex>
          </Flex>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      </Flex>
    </>
  );
};

export default LandingPagesHighlightsDetail;
