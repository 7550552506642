import {
  MenuOutlined,
  ReadOutlined,
  UserOutlined,
  YoutubeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { logoScoreQuest } from "../../Assets/images";
import Constant from "../../config/constans";
import "./index.scss";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Flex,
  Form,
  Image,
  Input,
  Menu,
  Row,
  Typography,
  message,
  Spin,
  Select,
  DatePicker,
  Upload,
  AutoComplete,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Title, Text } = Typography;
const { Option } = Select;

function AppHeader({ openLogin, showDrawerLogin, onCloseLogin }) {
  const [openForgot, setOpenForgot] = useState(false);
  const [openRegis, setOpenRegis] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [loadingRegis, setLoadingRegis] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [base64ImageRegis, setBase64ImageRegis] = React.useState(null);
  const [formLogin] = Form.useForm();
  const [formForgot] = Form.useForm();
  const [formRegis] = Form.useForm();
  const navigate = useNavigate();
  const statusLogin = localStorage.getItem("statusLogin");
  const [options, setOptions] = useState([]);
  const getStatusLogin = localStorage.getItem("statusLogin");
  const { t } = useTranslation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loginUser = (payload) => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LOGIN_USER;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.data.code !== "0") {
          setLoadingLogin(false);
          messageApi.open({
            type: "error",
            content: response.data.Message,
            duration: 4,
          });
          return;
        }
        localStorage.setItem("statusLogin", response.data.code);
        localStorage.setItem("idUserLogin", response.data.Data[0].id_user);
        localStorage.setItem("token", response.data.Data[0].token);
        setLoadingLogin(false);
        messageApi.open({
          type: "success",
          content: "Successfully logged in",
          duration: 4,
        });
        onCloseLogin();
        navigate("/");
        formLogin.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const forgotPassword = (values) => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_FORGOT_PASSWORD;
    const payload = {
      email: values.email,
    };
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.data.Code === "0") {
          setLoadingForgot(false);
          messageApi.open({
            type: "success",
            content: response.data.Data,
            duration: 4,
          });
          setOpenForgot(false);
          formForgot.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const registration = (payload) => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_REGISTRATION_USER;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.data.Code !== "0") {
          setLoadingRegis(false);
          messageApi.open({
            type: "error",
            content: response.data.Message,
            duration: 4,
          });
          return;
        }
        setLoadingRegis(false);
        messageApi.open({
          type: "success",
          content: "Successfully Registered",
          duration: 4,
        });
        setOpenRegis(false);
        setBase64ImageRegis(null);
        formRegis.resetFields();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const menuIcon = menuOpen ? <MenuOutlined /> : <MenuOutlined />;

  const logoutUser = () => {
    onCloseLogin();
    localStorage.removeItem("statusLogin");
    localStorage.removeItem("idUserLogin");
    localStorage.removeItem("token");
    navigate("/", { state: null });
  };

  const onFinishLogin = () => {
    setLoadingLogin(true);
    const values = formLogin.getFieldsValue();
    loginUser(values);
  };

  const onFinishForgot = () => {
    setLoadingForgot(true);
    const values = formForgot.getFieldsValue();
    forgotPassword(values);
  };

  const onFinishRegis = () => {
    setLoadingRegis(true);
    const values = formRegis.getFieldsValue();
    values.tanggal_lahir = values.tanggal_lahir.format("DD-MM-YYYY");
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageRegis) {
      const matchUpload = base64ImageRegis.match(regexUpload);
      const imageWithoutPrefix = base64ImageRegis.substring(
        matchUpload[0].length,
      );
      values.photo_profile = imageWithoutPrefix;
    }
    values.device_id = "";
    values.role = selectedRole?.id;
    registration(values);
  };

  const openForgotPassword = () => {
    formForgot.resetFields();
    onCloseLogin();
    setOpenForgot(true);
  };

  const openRegistration = () => {
    formRegis.resetFields();
    onCloseLogin();
    setOpenRegis(true);
  };

  const closeForgotPassword = () => {
    setOpenForgot(false);
    formForgot.resetFields();
  };

  const closeRegis = () => {
    setOpenRegis(false);
    formRegis.resetFields();
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setBase64ImageRegis(event.target.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const [selectedRole, setSelectedRole] = useState("");

  const getListRole = () => {
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ROLE_BEFORE_LOGIN;
    axios
      .get(URL)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          let tmp = [];
          data.map((item) => {
            tmp.push({ value: item?.nama_role, label: item?.id_role });
          });

          setOptions(tmp);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  useEffect(() => {
    getListRole();
  }, []);

  return (
    <Flex
      gap="middle"
      horizontal
      justify="space-between"
      align="center"
      className="web-header"
    >
      {contextHolder}
      <Link to="/">
        <Flex horizontal align="center" className="header-logo">
          <Image width={80} src={logoScoreQuest} preview={false} />
          <Title level={4}>Score Quest</Title>
        </Flex>
      </Link>
      <Flex horizontal justify="flex-end" className="header-menu">
        {statusLogin && statusLogin === "0" ? (
          isMobile ? (
            <Dropdown
              overlay={
                <Menu>
                  {getStatusLogin && (
                    <Menu.Item>
                      <div
                        onClick={() => {
                          navigate("team-saya", {
                            state: {
                              dataState: {
                                id_tournament: {},
                                isRegister: true,
                              },
                            },
                          });
                        }}
                      >
                        {t("MyTeam")}
                      </div>
                    </Menu.Item>
                  )}

                  <Menu.Item>
                    <a href="/room-tournament">Tournament</a>
                  </Menu.Item>
                  <Menu.Item>
                    <a href="https://mobilenation.id" target="_blank">
                      News
                    </a>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/highlights">Highlights</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/profile">Profile</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Button
                      style={{ backgroundColor: "red" }}
                      type="primary"
                      onClick={logoutUser}
                    >
                      Logout
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a onClick={() => toggleMenu()}>{menuIcon}</a>
            </Dropdown>
          ) : (
            <Flex
              horizontal
              align="center"
              gap={5}
              className="header-menu-button"
            >
              {getStatusLogin && (
                <Button type="primary" icon={<ReadOutlined />}>
                  <div
                    onClick={() => {
                      navigate("team-saya", {
                        state: {
                          dataState: {
                            id_tournament: {},
                            isRegister: true,
                          },
                        },
                      });
                    }}
                  >
                    {t("MyTeam")}
                  </div>
                </Button>
              )}

              <Button type="primary" icon={<ReadOutlined />}>
                <a href="/room-tournament">Tournament</a>
              </Button>
              <Button type="primary" icon={<ReadOutlined />}>
                <a href="https://mobilenation.id" target="_blank">
                  News
                </a>
              </Button>
              <Button type="primary" icon={<YoutubeOutlined />}>
                <Link to="/highlights">Highlights</Link>
              </Button>
              <Button type="primary" icon={<UserOutlined />}>
                <Link to="/profile">Profile</Link>
              </Button>
              <Button
                style={{ backgroundColor: "red" }}
                type="primary"
                onClick={logoutUser}
              >
                Logout
              </Button>
            </Flex>
          )
        ) : // Display buttons or burger menu based on screen size
        isMobile ? (
          <Dropdown
            overlay={
              <Menu>
                {getStatusLogin && (
                  <Menu.Item>
                    <div
                      onClick={() => {
                        navigate("team-saya", {
                          state: {
                            dataState: {
                              id_tournament: {},
                              isRegister: true,
                            },
                          },
                        });
                      }}
                    >
                      {t("MyTeam")}
                    </div>
                  </Menu.Item>
                )}

                <Menu.Item>
                  <a href="/room-tournament">Tournament</a>
                </Menu.Item>
                <Menu.Item>
                  <a href="https://mobilenation.id" target="_blank">
                    News
                  </a>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/highlights">Highlights</Link>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    type="primary"
                    onClick={showDrawerLogin}
                    icon={<UserOutlined />}
                  >
                    Login
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>{menuIcon}</a>
          </Dropdown>
        ) : (
          <Flex
            horizontal
            align="center"
            gap={16}
            className="header-menu-button"
          >
            {getStatusLogin && (
              <Button type="primary" icon={<ReadOutlined />}>
                <div
                  onClick={() => {
                    navigate("team-saya", {
                      state: {
                        dataState: {
                          id_tournament: {},
                          isRegister: true,
                        },
                      },
                    });
                  }}
                >
                  {t("MyTeam")}
                </div>
              </Button>
            )}

            <Button type="primary" icon={<ReadOutlined />}>
              <a href="/room-tournament">Tournament</a>
            </Button>
            <Button type="primary" icon={<ReadOutlined />}>
              <a href="https://mobilenation.id" target="_blank">
                News
              </a>
            </Button>
            <Button type="primary" icon={<YoutubeOutlined />}>
              <Link to="/highlights">Highlights</Link>
            </Button>
            <Button
              type="primary"
              onClick={showDrawerLogin}
              icon={<UserOutlined />}
            >
              Login
            </Button>
          </Flex>
        )}
      </Flex>
      <Drawer
        className="login-password-drawer"
        title="Login"
        width={400}
        closable={false}
        open={openLogin}
      >
        <Spin spinning={loadingLogin} tip="Loading...">
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={formLogin}
            onFinish={onFinishLogin}
          >
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  label={t("Email")}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input placeholder="Please enter email" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label={t("Password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={10} justify="flex-end">
                  <Text
                    className="forgot-password"
                    onClick={openForgotPassword}
                  >
                    {t("ForgotPassword")}
                  </Text>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex gap={10} justify="space-between">
                  <Flex>
                    <Text
                      className="forgot-password"
                      onClick={openRegistration}
                    >
                      {t("CreateAccount")}
                    </Text>
                  </Flex>
                  <Flex gap={10}>
                    <Button onClick={onCloseLogin}>{t("Cancel")}</Button>
                    <Button type="primary" htmlType="submit">
                      {t("SignIn")}
                    </Button>
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        className="forgot-password-drawer"
        title="Lupa Password"
        width={400}
        closable={false}
        open={openForgot}
      >
        <Spin spinning={loadingForgot} tip="Loading...">
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={formForgot}
            onFinish={onFinishForgot}
          >
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name={t("Email")}
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("EnterEmail"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnterEmail")} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={10} justify="flex-end">
                  <Button onClick={closeForgotPassword}>{t("Cancel")}</Button>
                  <Button type="primary" htmlType="submit">
                    {t("Send")}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
      <Drawer
        className="registration-drawer"
        title="Registration"
        width={400}
        closable={false}
        open={openRegis}
      >
        <Spin spinning={loadingRegis} tip="Loading...">
          <Form
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            form={formRegis}
            onFinish={onFinishRegis}
          >
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="nama"
                  label="Nama"
                  rules={[
                    {
                      required: true,
                      message: t("EnterName"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnterName")} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="nickname"
                  label={t("Nickname")}
                  rules={[
                    {
                      required: true,
                      message: t("EnterNickname"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnterNickname")} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Role"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: t("EnterRole"),
                    },
                  ]}
                >
                  <AutoComplete
                    options={options}
                    placeholder={t("SelectRole")}
                    optionRender={(e) => (
                      <div
                        onClick={() =>
                          setSelectedRole({ id: e.label, value: e.value })
                        }
                      >
                        {e?.value}
                      </div>
                    )}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="no_hp"
                  label={t("NoHandphone")}
                  rules={[
                    {
                      required: true,
                      message: t("EnterNoHandphone"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnterNoHandphone")} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="jenis_kelamin"
                  label={t("Gender")}
                  rules={[
                    {
                      required: true,
                      message: t("EnterGender"),
                    },
                  ]}
                >
                  <Select placeholder={t("SelectGender")}>
                    <Option key="L" value="L">
                      {t("Male")}
                    </Option>
                    <Option key="P" value="P">
                      {t("Female")}
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  label={t("Email")}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("EnterEmail"),
                    },
                  ]}
                >
                  <Input placeholder={t("EnterEmail")} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label={t("Password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("EnterPassword"),
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label={t("DateofBirth")}
                  name="tanggal_lahir"
                  rules={[
                    {
                      type: "object",
                      required: true,
                      message: t("EnterGender"),
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label="Photo Profile"
                  name="photo_profile"
                  rules={[
                    {
                      required: true,
                      message: t("EnterPhotoProfile"),
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    showUploadList={false}
                    onRemove={() => setBase64ImageRegis("")}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ marginBottom: "10px" }}
                    >
                      Upload Image
                    </Button>
                    {base64ImageRegis ? (
                      <img
                        src={base64ImageRegis}
                        alt="Uploaded"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    ) : null}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={10} justify="flex-end">
                  <Button onClick={closeRegis}>{t("Cancel")}</Button>
                  <Button type="primary" htmlType="submit">
                    {t("Send")}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </Flex>
  );
}

export default AppHeader;
