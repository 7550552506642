import { Flex, Image, Layout } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import success from "../../../Assets/images/tournament/icon/success.png";
import failed from "../../../Assets/images/tournament/icon/error.png";
import ButtonCustom from "../../../components/atoms/Button";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import colors from "../../../utils/colors";
import "./index.scss";
import LayoutMobile from "../../../components/LayoutMobile";
import { useTranslation } from "react-i18next";

const LandingPagesFinishRegister = ({}) => {
  const navigate = useNavigate();
  const { dataState, isSuccessRegister } = useLocation()?.state;
  const { t } = useTranslation();

  return (
    <LayoutMobile>
      {/* Header */}
      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
          padding: 20,
        }}
      >
        <Flex vertical justify="space-between">
          <Flex vertical align="center">
            <Gap height={120} />
            <Image
              preview={false}
              src={isSuccessRegister ? success : failed}
              style={{ height: 120, width: 120 }}
            />
            <Gap height={30} />
            <TextCustom
              fontSize={18}
              fontWeight="bold"
              color={colors.black}
              text={
                isSuccessRegister
                  ? t("CongratulationsOnRegisteringInThisTournament")
                  : t("OopsThereIsAProblemInRegisteringPleaseTryInAFewMoments")
              }
              style={{ textAlign: "center" }}
            />
            <Gap height={120} />
          </Flex>
          <ButtonCustom
            onClick={() => navigate("/room-tournament")}
            text={t("BackToHome")}
          />
        </Flex>
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesFinishRegister;
