import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import "./index.scss";
import Constant from "../../../config/constans";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Layout,
  Typography,
  Table,
  Upload,
  Spin,
  Image,
  message,
  Popconfirm,
  Empty,
  Flex,
  Divider,
  AutoComplete,
} from "antd";
import { useTranslation } from "react-i18next";
const { Text } = Typography;
const { Option } = Select;

const LandingPagesProfile = () => {
  const [editProfile, setEditProfile] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [dataDetailUser, setDataDetailUser] = useState(null);
  const [base64ImageEdit, setBase64ImageEdit] = useState("");
  const [fileNameEditUploadItem, setFileNameEditUploadItem] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loadingUpdateDetail, setLoadingUpdateDetail] = useState(false);
  const [editImage, setEditImage] = useState("");
  const [loadData, setLoadData] = useState(false);
  const idUser = localStorage.getItem("idUserLogin");
  const authToken = localStorage.getItem("token");
  const [formChange] = Form.useForm();
  const [formEdit] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const idUserLogin = localStorage.getItem("idUserLogin");
  const [optionsRole, setOptionsRole] = useState([]);
  const [roleUser, setRoleUser] = useState("");
  const { t } = useTranslation();

  const checkUserRole = (role_id) => {
    let data = optionsRole?.find((item) => item?.value == role_id);
    console.log("dataa", data);
    return data?.label;
  };

  const getListRole = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ROLE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          console.log(data, "ROLEE");
          let tmp = [];
          data?.map((item) => {
            tmp.push({ value: item?.id_role, label: item?.nama_role });
          });
          setOptionsRole(tmp);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDetailProfile();
    getListRole();
  }, [loadData]);

  const getDetailProfile = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_user: idUser,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_USER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200 && response.data.Code === "0") {
          setDataDetailUser(response.data.Data[0]);
          setLoadData(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const postChangePassword = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_CHANGE_PASSWORD;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200 && response.data.Code !== "0") {
          setLoadingChangePassword(false);
          messageApi.open({
            type: "error",
            content: response.data.Message,
            duration: 4,
          });
          return;
        }
        messageApi.open({
          type: "success",
          content: "Successfully Changed Password",
          duration: 4,
        });
        setTimeout(() => {
          setLoadingChangePassword(false);
          localStorage.removeItem("statusLogin");
          localStorage.removeItem("idUserLogin");
          localStorage.removeItem("token");
          navigate("/", { state: null });
        }, 2000);
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const postUpdateDetail = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_UPDATE_DETAIL_USER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200 && response.data.Code === "0") {
          messageApi.open({
            type: "success",
            content: "Successfully Update Profile Detail",
            duration: 4,
          });
          setTimeout(() => {
            setLoadData(true);
            setEditProfile(false);
            setLoadingUpdateDetail(false);
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFinishChangePassword = () => {
    setLoadingChangePassword(true);
    const values = formChange.getFieldsValue();
    values.id_user = idUserLogin;
    postChangePassword(values);
  };

  const onFinishEdit = () => {
    setLoadingUpdateDetail(true);
    const values = formEdit.getFieldsValue();
    // values.tanggal_lahir = values.tanggal_lahir.format('DD-MM-YYYY');
    values.tanggal_lahir = values.tanggal_lahir.format("YYYY-MM-DD");
    values.id_user = dataDetailUser.id_user;
    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageEdit) {
      const matchUpload = base64ImageEdit.match(regexUpload);
      const imageWithoutPrefix = base64ImageEdit.substring(
        matchUpload[0].length,
      );
      values.photo_profile = imageWithoutPrefix;
    } else {
      values.photo_profile = "-";
    }
    postUpdateDetail(values);
  };

  const beforeEditUploadItem = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageEdit(reader.result);
    };

    // Set the selected image for display
    setSelectedImage(file);

    const fileName = file.name;
    setFileNameEditUploadItem(fileName);

    return false;
  };

  const openDrawerEdit = () => {
    const dataDetail = dataDetailUser;
    const selectedDate = dayjs(dataDetail.tanggal_lahir);
    setEditImage(dataDetail.photo_profile);
    setEditProfile(true);
    formEdit.setFieldsValue({
      id_user: dataDetail.id_user,
      email: dataDetail.email,
      nama: dataDetail.nama,
      no_hp: dataDetail.no_hp,
      jenis_kelamin: dataDetail.jenis_kelamin,
      tanggal_lahir: selectedDate,
      photo_profile: dataDetail.photo_profile,
      role: dataDetail?.role,
      nickname: dataDetail?.nickname,
    });
  };

  const openChange = () => {
    formChange.resetFields();
    setOpenChangePassword(true);
  };

  const closeChangePassword = () => {
    setOpenChangePassword(false);
    formChange.resetFields();
  };

  const onCloseChangeDetail = () => {
    setBase64ImageEdit("");
    setFileNameEditUploadItem("");
    setSelectedImage(null);
    formEdit.resetFields();
    setEditProfile(false);
    setLoadData(true);
  };

  return (
    <Flex vertical className="web-score-profile">
      {contextHolder}
      {dataDetailUser && authToken && !editProfile ? (
        <Flex className="web-score-profile-detail">
          <Flex vertical gap={30} className="web-score-profile-detail-left">
            <Image
              preview={false}
              src={dataDetailUser.photo_profile}
              className="web-score-profile-detail-image"
            />
            <Flex
              vertical
              align="center"
              className="web-score-profile-detail-text"
            >
              <Text className="web-score-profile-detail-text-title">
                {t("FullName")}
              </Text>
              <Text className="web-score-profile-detail-text-value">
                {dataDetailUser.nama}
              </Text>
            </Flex>
            <Flex
              justify="center"
              gap={20}
              className="web-score-profile-detail-action"
            >
              <Button type="primary" onClick={openDrawerEdit}>
                <Text>Edit Profile</Text>
              </Button>
              <Button type="primary" onClick={openChange}>
                <Text>Change Password</Text>
              </Button>
            </Flex>
          </Flex>
          <Flex vertical gap={20}>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">Email</Text>
              <Text className="web-score-profile-detail-text-value">
                {dataDetailUser.email}
              </Text>
            </Flex>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">
                Nickname
              </Text>
              <Text className="web-score-profile-detail-text-value">
                {dataDetailUser.nickname}
              </Text>
            </Flex>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">Role</Text>
              <Text className="web-score-profile-detail-text-value">
                {checkUserRole(dataDetailUser?.role)}
              </Text>
            </Flex>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">
                {t("NoHandphone")}
              </Text>
              <Text className="web-score-profile-detail-text-value">
                {dataDetailUser.no_hp}
              </Text>
            </Flex>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">
                {t("Gender")}
              </Text>
              <Text className="web-score-profile-detail-text-value">
                {dataDetailUser?.jenis_kelamin === "L"
                  ? t("Male")
                  : t("Female")}
              </Text>
            </Flex>
            <Flex vertical className="web-score-profile-detail-text">
              <Text className="web-score-profile-detail-text-title">
                {t("DateofBirth")}
              </Text>
              <Text className="web-score-profile-detail-text-value">
                {moment(dataDetailUser.tanggal_lahir).format("DD MMMM YYYY")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Spin spinning={loadingUpdateDetail} tip="Loading...">
          <Form layout="vertical" form={formEdit} onFinish={onFinishEdit}>
            <Flex className="web-score-profile-detail-edit">
              <Flex
                vertical
                gap={30}
                className="web-score-profile-detail-edit-left"
              >
                <Flex
                  vertical
                  gap={20}
                  align="center"
                  className="web-score-profile-detail-text"
                >
                  <Form.Item
                    name="photo_profile"
                    label=""
                    rules={[
                      {
                        required: true,
                        message: "Silahkan Masukkan Photo Profile",
                      },
                    ]}
                  >
                    <Flex vertical gap={20} align="center">
                      {base64ImageEdit ? null : (
                        <Image
                          width={200}
                          src={editImage}
                          className="web-score-profile-detail-edit-image"
                        />
                      )}
                      <Flex vertical align="center" gap={20}>
                        {selectedImage && (
                          <Image
                            width={200}
                            src={URL.createObjectURL(selectedImage)}
                            className="web-score-profile-detail-edit-image"
                          />
                        )}
                        <Text style={{ color: "#ffffff" }}>
                          {fileNameEditUploadItem}
                        </Text>
                        <Upload
                          customRequest={() => {}}
                          fileList={[]}
                          beforeUpload={beforeEditUploadItem}
                          onRemove={() => {
                            setBase64ImageEdit("");
                            setSelectedImage(null); // Clear the selected image when removing
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            Select Image
                          </Button>
                        </Upload>
                      </Flex>
                    </Flex>
                  </Form.Item>
                  <Form.Item
                    name="nama"
                    label={t("FullName")}
                    rules={[
                      {
                        required: true,
                        message: "Silahkan Masukkan Nama Lengkap",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Flex>
                <Flex
                  justify="center"
                  gap={20}
                  className="web-score-profile-detail-action"
                >
                  <Button onClick={onCloseChangeDetail}>
                    <Text>{t("Cancel")}</Text>
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Flex>
              </Flex>
              <Flex
                vertical
                gap={30}
                className="web-score-profile-detail-edit-right"
              >
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="nickname"
                  label="Nickname"
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Nickname",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="role"
                  label="Role"
                  style={{ width: 150 }}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Role",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                    placeholder="Pilih role"
                  >
                    {optionsRole
                      ? optionsRole.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))
                      : null}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="no_hp"
                  label={t("NoHandphone")}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Nomer Handphone",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="jenis_kelamin"
                  label={t("Gender")}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Jenis Kelamin",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Pilih Jenis Kelamin"
                  >
                    <Option key="L" value="L">
                      {t("Male")}
                    </Option>
                    <Option key="P" value="P">
                      {t("Female")}
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="tanggal_lahir"
                  label={t("DateofBirth")}
                  rules={[
                    {
                      required: true,
                      message: "Silahkan Masukkan Tanggal Lahir",
                    },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
              </Flex>
            </Flex>
          </Form>
        </Spin>
      )}
      <Drawer
        className="nama_season-password-drawer"
        title="Change Password"
        width={400}
        closable={false}
        open={openChangePassword}
      >
        <Spin spinning={loadingChangePassword} tip="Loading...">
          <Form form={formChange} onFinish={onFinishChangePassword}>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  label={t("OldPassword")}
                  name="password_user_old"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item
                  label={t("NewPassword")}
                  name="password_user"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={10} justify="flex-end">
                  <Flex gap={10}>
                    <Button onClick={closeChangePassword}>{t("Cancel")}</Button>
                    <Button type="primary" htmlType="submit">
                      {t("Save")}
                    </Button>
                  </Flex>
                </Flex>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </Flex>
  );
};

export default LandingPagesProfile;
