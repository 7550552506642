import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import './index.scss';
import Constant from "../../../config/constans";
import { Link } from "react-router-dom";
import { Typography, Card, Flex, List, Pagination, Image } from 'antd';
const { Meta } = Card;
const { Text, Title } = Typography;

const LandingPagesHighlights = () => {
  const [dataVideo, setDataVideo] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    getListVideo();
  }, []);

  const getListVideo = () => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_VIDEO;
    axios
      .get(URL)
      .then((response) => {
        if (response.status === 200) {
          setDataVideo(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!dataVideo) {
    return null;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = dataVideo.slice(startIndex, endIndex);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Flex vertical className="web-content-highlights">
      <Title level={3} className="web-content-highlights-title">Highlights</Title>
      <List
        className="web-content-highlights-list"
        dataSource={currentItems}
        renderItem={(item) => (
          <List.Item className="web-content-highlights-list-item">
            <Link to={`/highlights/${item.id_video}`} state={{item}}>
              <Card
                className="web-content-news-list-card"
                hoverable
              >
                <Image preview={false} src={item.thumbnail_video}/>
                <Meta 
                  title={
                    <Title level={4}>{item.nama_video}</Title>
                  }
                  description={
                    <Flex>
                      <Flex vertical>
                        <Text style={{fontWeight:"bold"}}>{item.nama_kategori_game} | {item.nama_sub_kategori_game}</Text>
                        <Text>{moment(item.createdat).format("DD MMM YYYY HH:mm")}</Text>
                      </Flex>
                    </Flex>
                  } />
              </Card>
            </Link>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={dataVideo.length}
        onChange={handlePageChange}
      />
    </Flex>
  );
};

export default LandingPagesHighlights;
