import React from "react";
import colors from "../../utils/colors";
import { Button } from "antd";

export default function ButtonCustom({
  onClick,
  backgroundColor,
  color,
  variant,
  text,
  htmlType,
  width,
}) {
  return (
    <Button
      variant={variant ?? "contained"}
      onClick={onClick}
      style={{
        borderRadius: 100,
        backgroundColor: backgroundColor ?? colors.primary2,
        color: color ?? colors.white,
        padding: 20,
        width: width ?? "100%",
        height: 40,
        alignItems: "center",
        justifyContent: "center",
      }}
      htmlType={htmlType ? "submit" : "button"}
    >
      {text}
    </Button>
  );
}
