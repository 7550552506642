import { Flex, Image } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import colors from "../../utils/colors";
import Gap from "../atoms/Gap";
import TextCustom from "../atoms/Text";
import "./index.scss";

export default function ListItemScoreNonBracket({ item }) {
  const navigate = useNavigate();
  console.log("itemitem", item);

  return (
    <Flex
      // onClick={() => navigate(`/room-tournament/${id}`)}
      vertical
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
        width: "100%",
        marginRight: 10,
      }}
    >
      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Flex vertical>
          <TextCustom
            fontSize={14}
            color={colors.black}
            fontWeight="bold"
            text={item?.name_tournament}
          />
          <TextCustom
            fontSize={12}
            color={colors.black}
            text={`${item?.name_kategori_game} (${item?.name_maps})`}
          />
        </Flex>
        <TextCustom
          fontSize={12}
          color={colors.black}
          text={moment(item?.createat).format("DD-MM-YYYY")}
        />
      </Flex>
      <Gap height={10} />

      {item?.list_score?.map((score) => (
        <Flex
          gap={5}
          align="center"
          justify="space-between"
          style={{ width: "100%", marginBottom: 10 }}
        >
          <Flex align="center">
            <Image
              preview={true}
              src={score?.image_team}
              style={{ borderRadius: 30, height: 30, width: 30 }}
            />
            <Gap width={10} />
            <TextCustom
              fontSize={14}
              color={colors.black}
              text={score?.name_team}
            />
          </Flex>
          <TextCustom
            fontSize={14}
            color={colors.black}
            text={`${score?.kalkulasi} Point`}
          />
        </Flex>
      ))}
    </Flex>
  );
}
