import { Flex, Image } from "antd";
import React from "react";
import activeMember from "../../Assets/images/tournament/icon/activeMember.png";
import inActiveMember from "../../Assets/images/tournament/icon/inActiveMember.png";
import add from "../../Assets/images/tournament/icon/add.png";
import mvp from "../../Assets/images/tournament/icon/mvp.png";
import trash from "../../Assets/images/tournament/icon/trash.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import TextCustom from "../atoms/Text";
import colors from "../../utils/colors";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

// type = trash, add, role, player
export default function ListItemTournament({
  item,
  type = "trash",
  onClickIcon,
  dataDetailTournament,
}) {
  const navigate = useNavigate();
  const Icon = () => {
    switch (type) {
      case "mvp":
        return mvp;
      case "add":
        return add;
      case "player":
        return item?.is_approved ? activeMember : inActiveMember;
      case "trash":
        return trash;

      default:
        return trash;
    }
  };
  return (
    <Flex
      onClick={() =>
        navigate(`/room-tournament/${item?.id_tournament}`, {
          state: {
            dataState: item,
          },
        })
      }
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
      }}
    >
      <Flex align="center" justify="space-between" gap={"small"}>
        <Image
          preview={true}
          src={item?.banner}
          style={{ borderRadius: 50, height: 50, width: 50 }}
        />
        <Flex vertical>
          <TextCustom
            fontSize={12}
            fontWeight="bold"
            color={colors.black}
            text={item?.nama_tournament}
          />
          <TextCustom
            fontSize={12}
            color={colors.black}
            text={item?.nama_kategori_game}
          />
          <TextCustom
            text={`${dayjs(item?.start_date).format("DD MMMM")} -  ${dayjs(
              item?.end_date,
            ).format("DD MMMM YYYY")}`}
          ></TextCustom>
        </Flex>
      </Flex>
      <Flex>
        {dataDetailTournament?.champions == item?.id_team &&
          type != "tournament" && (
            <div
              style={{
                backgroundColor: colors.primary1,
                position: "absolute",
                right: 0,
                top: 0,
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 10,
                padding: 4,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <TextCustom fontSize={12} color={colors.white} text="Champions" />
            </div>
          )}
        {/* <Image
          preview={false}
          src={Icon()}
          onClick={onClickIcon}
          // onClick={() => onClickIcon(item)}
          style={{
            height: 25,
            width: 25,
            marginTop: type == "mvp" || type == "player" ? 20 : 0,
          }}
        /> */}
      </Flex>
    </Flex>
  );
}
