import { Flex, Input, Layout, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import HeaderMobile from "../../../components/HeaderMobile";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemLeaderboard from "../../../components/ListItemLeaderboard";
import colors from "../../../utils/colors";
import "./index.scss";
import { useEffect } from "react";
import Constant from "../../../config/constans";
import axios from "axios";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";

const LandingPagesLeaderboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const authToken = localStorage.getItem("token");
  const idUser = localStorage.getItem("idUserLogin");
  const [selectTab, setSelectTab] = useState({
    key: "1",
    label: "Team",
    children: "",
  });
  const [selectTabGame, setSelectTabGame] = useState();
  const [dataListTeam, setDataListTeam] = useState([]);
  const [dataListTeamFilter, setDataListTeamFilter] = useState([]);
  const [dataListPlayer, setDataListPlayer] = useState([]);
  const [listTabGame, setListTabGame] = useState([]);
  const [filteredDataSourceTeam, setFilteredDataSourceTeam] = useState([]);
  const [filteredDataSourcePlayer, setFilteredDataSourcePlayer] = useState([]);
  const [searchText, setSearchText] = useState("");

  const itemsTab = [
    {
      key: "1",
      label: "Team",
      children: "",
    },
    {
      key: "2",
      label: "Player",
      children: "",
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);

    if (selectTab?.label == "Team") {
      const filteredData = dataListTeamFilter[0]?.listScore?.filter(
        (record) => {
          return Object.values(record).some(
            (fieldValue) =>
              fieldValue &&
              fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
          );
        },
      );
      setFilteredDataSourceTeam(filteredData);
    } else {
      const filteredData = dataListPlayer?.filter((record) => {
        return Object.values(record).some(
          (fieldValue) =>
            fieldValue &&
            fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
        );
      });
      console.log("filter", filteredData);
      setFilteredDataSourcePlayer(filteredData);
    }
  };

  const getListLeaderboardTeam = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_LEADERBOARD_TEAM;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataListTeam(response.data.Data[0]);
          let game = [];
          response.data.Data.map((item, index) => {
            game.push({
              key: index,
              label: item?.nama_kategori_game,
            });
          });
          setDataListTeam(response.data.Data);
          setListTabGame(uniq(game));
          setSelectTabGame(game[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const getListLeaderboardPlayer = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_LEADERBOARD_PLAYER;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataListPlayer(response.data.Data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const filterDataTeam = () => {
    let filter = dataListTeam?.filter(
      (item) => item?.nama_kategori_game == selectTabGame?.label,
    );
    setDataListTeamFilter(filter);
  };

  useEffect(() => {
    filterDataTeam();
  }, [dataListTeam, selectTabGame]);

  useEffect(() => {
    getListLeaderboardPlayer();
    getListLeaderboardTeam();
  }, []);

  return (
    <LayoutMobile>
      {/* Header */}
      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
          padding: 20,
        }}
      >
        <HeaderMobile title={t("Leaderboard")} isSearch={false} />
        <Gap height={15} />

        <section>
          <Flex gap={"large"}>
            {itemsTab.map((item) => {
              return (
                <div>
                  <section
                    onClick={() => {
                      setSelectTab(item);
                      setSearchText("");
                    }}
                    style={{
                      borderBottom:
                        selectTab.label == item.label
                          ? `3px solid ${colors.primary1}`
                          : "",
                    }}
                  >
                    <TextCustom
                      fontSize={14}
                      fontWeight="bold"
                      color={colors.black}
                      text={item.label}
                    />
                  </section>
                </div>
              );
            })}
          </Flex>
          <Gap height={15} />
        </section>
        <Gap height={10} />
        <Input
          onChange={(e) => handleSearch(e.target.value)}
          value={searchText}
          style={{ padding: 10 }}
          placeholder={
            selectTab?.label == "Team"
              ? t("SearchTeam")
              : t("SearchParticipants")
          }
          allowClear
        />
        <Gap height={20} />
        {selectTab.label == "Team" && (
          <>
            <section>
              <Flex gap={"large"}>
                {listTabGame?.map((item) => {
                  return (
                    <div>
                      <section
                        onClick={() => {
                          setSelectTabGame(item);
                          setSearchText("");
                        }}
                        style={{
                          borderBottom:
                            selectTabGame.label == item.label
                              ? `3px solid ${colors.primary1}`
                              : "",
                        }}
                      >
                        <TextCustom
                          fontSize={14}
                          fontWeight="bold"
                          color={colors.black}
                          text={item.label}
                        />
                      </section>
                    </div>
                  );
                })}
              </Flex>
              <Gap height={10} />
            </section>
            <Gap height={20} />
          </>
        )}
        {console.log("dataListTeamFilter", dataListTeamFilter[0]?.listScore)}
        {selectTab?.label == "Team" ? (
          <>
            {searchText ? (
              filteredDataSourceTeam?.map((item) => {
                return (
                  <ListItemLeaderboard
                    item={item}
                    nama={item?.nama_team}
                    image={item?.image_team}
                    point={item?.point}
                  />
                );
              })
            ) : dataListTeamFilter[0]?.listScore?.length ? (
              dataListTeamFilter[0]?.listScore?.map((item) => {
                return (
                  <ListItemLeaderboard
                    item={item}
                    nama={item?.nama_team}
                    image={item?.image_team}
                    point={item?.point}
                  />
                );
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </>
        ) : (
          <>
            {searchText ? (
              filteredDataSourcePlayer?.map((item) => {
                return (
                  <ListItemLeaderboard
                    item={item}
                    nama={item?.nama_player}
                    image={item?.photo_profile}
                    point={item?.point}
                  />
                );
              })
            ) : dataListPlayer?.length ? (
              dataListPlayer?.map((item) => {
                return (
                  <ListItemLeaderboard
                    item={item}
                    nama={item?.nama_player}
                    image={item?.photo_profile}
                    point={item?.point}
                  />
                );
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </>
        )}
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesLeaderboard;
