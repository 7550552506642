import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Empty,
  Flex,
  Image,
  Menu,
  Space,
  Typography,
} from "antd";
import axios from "axios";
import { addDays, format } from "date-fns";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from "react-horizontal-datepicker";
import { Link } from "react-router-dom";
import Constant from "../../../config/constans";
import "./index.scss";
import LayoutMobile from "../../../components/LayoutMobile";
const { Text } = Typography;
const { Meta } = Card;
const LandingPagesByKategori = (props) => {
  const [current, setCurrent] = useState("0");
  const [dataSubKategori, setDataSubKategori] = useState(null);
  const [dataJadwalPertandingan, setDataJadwalPertandingan] = useState(null);
  // const [selectedDate, setSelectedDate] = useState(dayjs());
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const calc = (persen) => calc(Number(persen) - 40);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [props.idKategori]);
  useEffect(() => {
    getListSubKategoriGameByKategori();
    getListJadwalPertandinganByKategori(selectedDate);
  }, [props.idKategori]);

  const getListJadwalPertandinganByKategori = (tanggal_tanding) => {
    const formattedDate = tanggal_tanding
      ? dayjs(tanggal_tanding).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
    const payload = {
      id_kategori_game: props.idKategori,
      tanggal_tanding: formattedDate,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataJadwalPertandingan(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListJadwalPertandinganByKategoriAndSubKategori = (id_sub) => {
    const payload = {
      id_kategori_game: props.idKategori,
      id_sub_kategori_game: id_sub,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI_AND_SUB_KATEGORI;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataJadwalPertandingan(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListSubKategoriGameByKategori = () => {
    const payload = {
      id_kategori_game: props.idKategori,
    };
    const URL =
      Constant.URL_MASTER_PATH +
      Constant.URL_GET_LISTS_SUB_KATEGORI_BY_KATEGORI;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataSubKategori(response?.data?.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleDateChange = (date, dateString) => {
  //   setSelectedDate(date);
  //   getListJadwalPertandinganByKategori(date)
  // };

  const handleChoiceSubKategori = (e) => {
    const id_item = e.item.props.id_item;
    setCurrent(e.key);
    console.log(e.key);
    if (e.key === "semua") {
      return getListJadwalPertandinganByKategori(dayjs());
    }
    return getListJadwalPertandinganByKategoriAndSubKategori(e.key);
  };

  // const mappingSubKategori = dataSubKategori
  //   ? [
  //     { key: 'semua', label: 'Semua' },
  //     ...dataSubKategori.map((item) => ({
  //       key: item.id_sub_kategori_game,
  //       label: item.nama_sub_kategori_game,
  //     })),
  //   ]
  //   : null;

  // const mappingSubKategori = dataSubKategori
  // ? dataSubKategori.map((item, index) => (
  //   // console.log(item,'subkategori'),
  //   {
  //     key: `${index}`,
  //     id_item: item.id_sub_kategori_game,
  //     label: item.nama_sub_kategori_game,
  //     image: item.image_sub_kategori_game
  //   }))
  // : null;

  // console.log(typeof current, current)
  const selectedDay = (val) => {
    console.log("asdasf", val);
    const formattedDate = dayjs(val).format("YYYY-MM-DD");
    getListJadwalPertandinganByKategori(formattedDate);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const daysToShow = 7; // Number of days to display

  const handleDateClick = (date) => {
    setSelectedDate(date);
    console.log("date", date);
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    console.log("formattedDate", formattedDate);
    getListJadwalPertandinganByKategori(formattedDate);
  };

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < daysToShow; i++) {
      const day = addDays(selectedDate, i);
      days.push(
        <div
          key={i}
          className={`day-item ${i === 0 ? "selected" : ""}`}
          onClick={() => handleDateClick(day)}
        >
          <div className="day-label">{format(day, "E")}</div>
          <div className="date-label">{format(day, "d")}</div>
        </div>,
      );
    }
    return days;
  };
  return (
    <LayoutMobile>
      <Flex vertical className="web-content-by-kategori">
        <Flex
          className="web-content-datepicker-jadwal-pertandingan"
          style={{ position: "relative", marginTop: 40 }}
        >
          <div className="horizontal-date-picker">
            <div
              style={{
                display: "flex",
                position: "absolute",
                textAlign: "center",
                justifyContent: "center",
                top: -10,
              }}
            >
              <div style={{ fontSize: 16, textAlign: "center" }}>
                {dayjs(selectedDate).format("MMMM")}
              </div>
            </div>
            <div>
              <div
                style={{ borderRadius: 20 }}
                onClick={() =>
                  setSelectedDate(addDays(selectedDate, -daysToShow))
                }
              >
                {/* &lt; */}
                <ArrowLeftOutlined style={{ marginRight: -5 }} />
              </div>
            </div>
            <div className="days-container">{renderDays()}</div>
            <div
              style={{ borderRadius: 20 }}
              onClick={() => setSelectedDate(addDays(selectedDate, daysToShow))}
            >
              {/* &gt; */}
              <ArrowRightOutlined style={{ marginLeft: -5 }} />
            </div>
          </div>
        </Flex>
        <div
          style={{
            width: "100%",
          }}
        >
          <Menu
            onClick={handleChoiceSubKategori}
            selectedKeys={current}
            mode="horizontal"
            className="web-content-by-kategori-sub-menu"
            style={{
              width: isMobile ? "80%" : "100%",
              overflowX: "scroll",
              // backgroundColor: "red",
            }}
          >
            {dataSubKategori
              ? dataSubKategori.map((item, index) => (
                  <Menu.Item
                    key={item.id_sub_kategori_game}
                    className="web-sub-header-list"
                  >
                    <Flex horizontal align="center">
                      <Image
                        preview={false}
                        width={20}
                        src={item.image_sub_kategori_game}
                      />
                      <Text
                        strong
                        style={{ marginLeft: "10px" }}
                        className="web-sub-header-list-title"
                      >
                        {item.nama_sub_kategori_game}
                      </Text>
                    </Flex>
                  </Menu.Item>
                ))
              : null}
          </Menu>
        </div>

        {/* <Menu onClick={handleChoiceSubKategori} selectedKeys={current} mode="horizontal" items={mappingSubKategori} className="web-content-by-kategori-sub-menu"/> */}
        {/* <Space>
        <DatePicker
          onChange={handleDateChange}
          defaultValue={selectedDate}
          placement="topLeft"
          className="web-content-datepicker-jadwal-pertandingan"
        />
      </Space> */}
        <div
          style={{
            overflowX: "scroll",
          }}
        >
          <Flex
            direction="vertical"
            className="web-content-wrapper-card-jadwal-pertandingan"
            style={{
              width: "95%",
            }}
          >
            {dataJadwalPertandingan ? (
              dataJadwalPertandingan.map((item) => (
                <Card
                  className="web-content-card-jadwal-pertandingan"
                  title={
                    <Link
                      to={`/${props.path}/line-up`}
                      state={{ item, path: props.path }}
                    >
                      <Flex justify="space-between">
                        <Meta
                          avatar={<Avatar src={item.image_sub_kategori_game} />}
                          title={item.nama_kategori_game}
                          description={item.nama_season}
                        />
                        <RightOutlined style={{ color: "#ffffff" }} />
                      </Flex>
                    </Link>
                  }
                >
                  <Flex align="center">
                    <Text>{item.jam_tanding}</Text>
                    <Flex vertical gap={"middle"}>
                      <Flex justify="space-between">
                        <Flex align="center" className="web-content-card-team">
                          <Image
                            width={40}
                            height={40}
                            preview={false}
                            src={item.image_team_1}
                          />
                          <Text>{item.nama_team_1}</Text>
                        </Flex>
                        {item.flag_live !== "0" ? (
                          <Text className="web-content-card-team-score">
                            {item.score1}
                          </Text>
                        ) : null}
                      </Flex>
                      <Flex justify="space-between">
                        <Flex align="center" className="web-content-card-team">
                          <Image
                            width={40}
                            height={40}
                            preview={false}
                            src={item.image_team_2}
                          />
                          <Text>{item.nama_team_2}</Text>
                        </Flex>
                        {item.flag_live !== "0" ? (
                          <Text className="web-content-card-team-score">
                            {item.score2}
                          </Text>
                        ) : null}
                      </Flex>
                    </Flex>
                  </Flex>
                  <Space className="status-pertandingan">
                    <Text>
                      {item.flag_live === "0"
                        ? moment(item.tanggal_tanding).format("DD MMM YYYY")
                        : item.flag_live === "1"
                        ? "LIVE"
                        : "END GAME"}
                    </Text>
                  </Space>
                </Card>
              ))
            ) : (
              <Flex justify="center" style={{ width: "100%" }}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Flex>
            )}
          </Flex>
        </div>
      </Flex>
    </LayoutMobile>
  );
};

export default LandingPagesByKategori;
