import {
  Card,
  Col,
  Flex,
  Image,
  Layout,
  message,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { useState } from "react";
import arrowLeftWhite from "../../../Assets/images/tournament/icon/arrow-left-white.png";
import search from "../../../Assets/images/tournament/icon/search.png";
import mobileLegendXL from "../../../Assets/images/tournament/mobile-legend-xl.png";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemPlayer from "../../../components/ListItemPlayer";
import ListItemMember from "../../../components/ListItemMember";
import colors from "../../../utils/colors";
import convertToRupiah from "../../../utils/convertToRupiah";
import ButtonCustom from "../../../components/atoms/Button";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { useEffect } from "react";
import Constant from "../../../config/constans";
import axios from "axios";
import dayjs from "dayjs";
import { uniq } from "lodash";
import { useTranslation } from "react-i18next";

const LandingPagesDetailRoomTournament = () => {
  const idUser = localStorage.getItem("idUserLogin");
  const authToken = localStorage.getItem("token");
  const [dataUser, setDataUser] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const [dataDetailTournament, setDataDetailTournament] = useState({});
  const [dataListPeserta, setDataListPeserta] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [openModalCheckTeam, setOpenModalCheckTeam] = useState(false);
  const [dataModalCheckTeam, setdataModalCheckTeam] = useState(false);
  const { dataState } = useLocation()?.state;
  const [loadingData, setLoadingData] = useState(false);
  const [checkTournament, setcheckTournament] = useState([]);
  const [listTab, setListTab] = useState([]);
  const { t } = useTranslation();

  const [selectTab, setSelectTab] = useState({
    key: "1",
    label: t("Detail"),
    children: (
      <div
        dangerouslySetInnerHTML={{
          __html: `${dataDetailTournament?.description || ""}`,
        }}
      />
    ),
  });

  const itemlistCard = [
    {
      title: t("RegistrationFee"),
      value: convertToRupiah(dataDetailTournament?.registration_fee || 0),
    },
    {
      title: t("PrizePool"),
      value: convertToRupiah(dataDetailTournament?.total_price || 0),
    },
    {
      title: t("Participants"),
      value: dataDetailTournament?.total_peserta || 0,
    },
  ];

  const itemsTab = [
    {
      key: "1",
      label: t("Detail"),
      children: (
        <Flex
          vertical
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "50vh",
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `${dataDetailTournament?.description || ""}`,
            }}
          />
        </Flex>
      ),
    },
    {
      key: "2",
      label: t("Participants"),
      children: (
        <Flex
          vertical
          style={{
            width: "100%",
            overflowX: "scroll",
            height: "50vh",
          }}
        >
          {dataListPeserta?.length ? (
            dataListPeserta?.map((item, index) => {
              return (
                <ListItemMember
                  item={item}
                  type={"player"}
                  dataDetailTournament={dataDetailTournament}
                />
              );
            })
          ) : (
            <TextCustom
              fontSize={12}
              text={t("DataNotFound")}
              style={{ textAlign: "center", marginTop: 20 }}
            />
          )}
        </Flex>
      ),
    },
  ];

  const handleDynamicTab = () => {
    if (dataDetailTournament?.isbracket) {
      let list = [
        ...itemsTab,
        {
          key: "3",
          label: t("Bracket"),
          children: (
            <Flex
              vertical
              style={{
                width: "100%",
                overflowX: "scroll",
                height: "50vh",
              }}
            >
              {dataDetailTournament?.bracket_url_initial &&
              dataDetailTournament?.bracket_url ? (
                <>
                  <iframe
                    src={
                      `https://challonge.com/${dataDetailTournament?.bracket_url_initial}/module` ||
                      `${dataDetailTournament?.bracket_url}/module`
                    }
                    height="500"
                    width="100%"
                  ></iframe>
                </>
              ) : (
                <TextCustom
                  fontSize={12}
                  text={t("TournamentHasNotStartedYet")}
                  style={{ textAlign: "center", marginTop: 20 }}
                />
              )}
            </Flex>
          ),
        },
      ];
      setListTab(uniq(list));
    } else {
      let list = [
        ...itemsTab,
        {
          key: "3",
          label: t("Ranks"),
          children: (
            <Flex
              vertical
              style={{
                width: "100%",
                overflowX: "scroll",
                height: "50vh",
              }}
            >
              {dataDetailTournament?.list_score_kill?.length > 0 ? (
                dataDetailTournament?.list_score_kill?.map((score, index) => (
                  <Flex
                    align="center"
                    justify="space-between"
                    style={{
                      width: "100%",
                      marginBottom: 10,
                      backgroundColor: colors.secondary1,
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <Flex align="center">
                      <TextCustom
                        fontSize={20}
                        fontWeight="Bold"
                        color={colors.black}
                        text={`#${index + 1}`}
                      />
                      <Gap width={5} />
                      <Image
                        preview={true}
                        src={score?.image_team}
                        style={{ borderRadius: 30, height: 30, width: 30 }}
                      />
                      <Gap width={10} />
                      <TextCustom
                        fontSize={12}
                        fontWeight="Bold"
                        color={colors.black}
                        text={score?.name_team}
                      />
                    </Flex>
                    <TextCustom
                      fontSize={12}
                      color={colors.black}
                      fontWeight="Bold"
                      text={`${score?.kalkulasi} Point`}
                    />
                  </Flex>
                ))
              ) : (
                <TextCustom
                  fontSize={12}
                  text={t("TournamentHasNotStartedYet")}
                  style={{ textAlign: "center", marginTop: 20 }}
                />
              )}
            </Flex>
          ),
        },
      ];
      setListTab(uniq(list));
    }
  };

  console.log("dataaadetaill", dataDetailTournament);
  const getDetailTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TOURNAMENT;

    axios
      .post(URL, { id_tournament: id }, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          setDataDetailTournament(data);
          console.log("datadataaa", data?.list_peserta);
          let champions = [];
          let tmpArr = [];
          if (data?.list_peserta) {
            data?.list_peserta?.map((item) => {
              if (data?.champions == item?.id_team) {
                champions = [{ ...item }];
              } else {
                tmpArr.push(item);
              }
            });
          }
          setDataListPeserta([...champions, ...tmpArr]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const getDetailProfile = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_user: idUser,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_USER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataUser(response.data.Data[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleDeleteTeam = () => {
    setLoadingData(true);
    let check = dataUser?.flag_team?.filter(
      (item) =>
        item?.id_kategori_game == dataDetailTournament?.id_kategori_game,
    );
    console.log(check, "CHECKKKK");
    let payload = {
      id_team: check[0]?.id_team,
      id_kategori_game: dataDetailTournament?.id_kategori_game,
      id_user_delete: dataUser?.id_user,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_TEAM;

    console.log("PAYLOAD delete team", payload, dataDetailTournament, dataUser);
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          messageApi.open({
            type: "success",
            content: "Berhasil menghapus team",
            duration: 4,
          });
          setLoadingData(false);
          setOpenModalCheckTeam(false);
          navigate("/register-tournament", {
            state: { dataState: dataDetailTournament },
          });
        }
      })
      .catch((error) => {
        setLoadingData(false);
        setOpenModalCheckTeam(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleDetailTeam = () => {
    let checkTeamUser = dataUser?.flag_team?.filter(
      (item) =>
        item?.id_kategori_game == dataDetailTournament?.id_kategori_game,
    );
    let checkTeamTournament = dataDetailTournament.list_peserta?.filter(
      (item) => item.id_team == checkTeamUser[0].id_team,
    );
    console.log(
      "object",
      checkTeamUser,
      checkTeamTournament,
      dataUser,
      dataDetailTournament,
      // dataState,
    );
    navigate("/detail-team", {
      state: {
        dataState: {
          ...checkTeamUser[0],
          id_tournament: dataDetailTournament?.id_tournament,
          isRegister: true,
        },
      },
    });
  };

  useEffect(() => {
    getDetailTournament();
    getDetailProfile();
  }, []);

  const handleCheckTournament = () => {
    let checkTournamentGame = dataUser?.flag_team?.filter(
      (item) => item?.id_tournament == dataDetailTournament?.id_tournament,
    );

    setcheckTournament(checkTournamentGame);
  };

  useEffect(() => {
    handleCheckTournament();
  }, [dataUser, dataDetailTournament]);

  const handleRegister = () => {
    let checkCategoriGame = dataUser?.flag_team?.filter(
      (item) =>
        item?.id_kategori_game == dataDetailTournament?.id_kategori_game,
    );

    // let checkTournamentGame = dataUser?.flag_team?.filter(
    //   (item) => item?.id_tournament == dataDetailTournament?.id_tournament,
    // );

    // setcheckTournament(checkTournamentGame);
    console.log(
      "checkCategoriGame",
      checkCategoriGame,
      dataUser,
      dataDetailTournament,
    );

    if (checkCategoriGame?.length && checkCategoriGame[0]?.status_daftar) {
      setOpenModalCheckTeam(true);
      setdataModalCheckTeam(checkCategoriGame[0]);
    } else {
      navigate("/register-tournament", {
        state: { dataState: dataDetailTournament },
      });
    }
  };

  useEffect(() => {
    setSelectTab({
      key: "1",
      label: "Detail",
      children: (
        <div
          dangerouslySetInnerHTML={{
            __html: `${dataDetailTournament?.description || ""}`,
          }}
        />
      ),
    });
    handleDynamicTab();
  }, [dataDetailTournament]);

  return (
    <Spin spinning={loadingData} tip="Loading...">
      <LayoutMobile>
        {contextHolder}

        <Layout
          className="mobile-first-layout"
          style={{
            width: "100%",
            backgroundColor: colors.white,
            position: "relative",
          }}
        >
          {/* Header */}
          <section style={{ position: "relative" }}>
            <section style={{ position: "relative", top: 0, zIndex: 8 }}>
              <Image
                src={dataDetailTournament.banner}
                width={"100%"}
                style={{
                  width: "100%",
                  height: 400,
                  objectFit: "cover",
                }}
              />
              <Flex
                vertical
                style={{ position: "absolute", bottom: 0, margin: 20 }}
              >
                <TextCustom
                  color={colors.white}
                  text={dataDetailTournament?.nama_kategori_game}
                />
                <TextCustom
                  fontWeight="bold"
                  fontSize={14}
                  color={colors.white}
                  text={dataDetailTournament?.nama_tournament}
                />
                <TextCustom
                  color={colors.white}
                  text={`${dataDetailTournament?.nama_kategori_game} | ${dayjs(
                    dataDetailTournament?.start_date,
                  ).format("DD MMMM")} - ${dayjs(
                    dataDetailTournament?.end_date,
                  ).format("DD MMMM YYYY")}`}
                />
              </Flex>
            </section>
            <Flex
              justify="space-between"
              style={{
                padding: 20,
                position: "absolute",
                zIndex: 100,
                top: 0,
              }}
            >
              <Flex
                align="center"
                style={{
                  backgroundColor: "transparent",
                  zIndex: 10,
                  position: "relative",
                }}
              >
                <Image
                  preview={false}
                  width={20}
                  src={arrowLeftWhite}
                  onClick={() => navigate(-1)}
                />

                <TextCustom
                  fontSize={16}
                  fontWeight="bold"
                  color={colors.white}
                  text={t("DetailTournament")}
                />
              </Flex>
              {/* <Image preview={false} width={25} src={search} /> */}
            </Flex>
          </section>

          <Layout
            style={{
              padding: 20,
              backgroundColor: colors.white,
            }}
          >
            <Flex
              style={{
                overflowX: "scroll",
                whiteSpace: "nowrap",
              }}
            >
              {itemlistCard?.map((item, index) => {
                return (
                  <Flex
                    vertical
                    wrap
                    style={{
                      backgroundColor: colors.secondary1,
                      width: 300,
                      borderRadius: 10,
                      margin: 10,
                      marginLeft: index == 0 ? 0 : 10,
                    }}
                  >
                    <Flex vertical style={{ margin: 10 }}>
                      <TextCustom
                        fontSize={12}
                        color={colors.black}
                        fontWeight="bold"
                        text={item?.title}
                      />
                      <Flex vertical>
                        <Flex vertical>
                          <TextCustom
                            fontWeight="bold"
                            fontSize={18}
                            color={colors.primary2}
                            text={item?.value}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            <Gap height={20} />

            <section>
              <Flex gap={"large"}>
                {listTab.map((item) => {
                  return (
                    <div>
                      <section
                        onClick={() => setSelectTab(item)}
                        style={{
                          borderBottom:
                            selectTab.label == item.label
                              ? `3px solid ${colors.primary1}`
                              : "",
                        }}
                      >
                        <TextCustom
                          fontSize={14}
                          fontWeight="bold"
                          color={colors.black}
                          text={item.label}
                        />
                      </section>
                    </div>
                  );
                })}
              </Flex>
              <Gap height={15} />

              <Flex style={{ color: colors.black, width: "100%" }}>
                {selectTab.children}
              </Flex>
            </section>

            <Gap height={20} />

            {selectTab.key == 1 &&
              dataDetailTournament?.status == 1 &&
              checkTournament?.length < 1 && (
                <ButtonCustom
                  onClick={() => handleRegister()}
                  text={t("Register")}
                />
              )}
          </Layout>
        </Layout>
        <Modal
          title=""
          closable={false}
          open={openModalCheckTeam}
          // onCancel={() => setIsModalSearch(false)}
          footer={null}
          style={{
            overflowX: "scroll",
            height: "70%",
          }}
          // wrapStyle={{ height: window.innerHeight - 200,  }}
        >
          <Flex vertical style={{ textAlign: "center" }}>
            <Gap height={20} />

            <TextCustom
              fontWeight="bold"
              fontSize={14}
              color={colors.black}
              text={t("YoureAlreadyOnTheTeamDoYouWantToUseThisTeam", {
                name: dataModalCheckTeam?.nama_team,
              })}
            />
            <Gap height={20} />
            <TextCustom
              fontSize={14}
              color={colors.black}
              text={t("OtherwiseYouBeRemovedFromTheCurrentTeam", {
                name: dataModalCheckTeam?.nama_team,
              })}
            />
          </Flex>
          <Gap height={40} />
          <Flex gap={"large"}>
            <ButtonCustom
              onClick={() => handleDetailTeam()}
              text={t("Yes")}
              backgroundColor={colors.primary2}
            />
            <ButtonCustom
              onClick={() => handleDeleteTeam()}
              text={t("No")}
              backgroundColor={colors.red}
            />
          </Flex>
        </Modal>
      </LayoutMobile>
    </Spin>
  );
};

export default LandingPagesDetailRoomTournament;
