import React, { useState, useEffect } from "react";
import axios from "axios";
import Constant from "../../../config/constans";
import { LeftOutlined } from "@ant-design/icons";
import "./index.scss";
import { Link, useLocation } from "react-router-dom";
import { Typography, Image, Flex, Card, Empty, Avatar, Badge } from "antd";
import browserIcon from "../../../Assets/images/icon_browser.png";
import tiktokIcon from "../../../Assets/images/icon_tiktok.png";
import crownIcon from "../../../Assets/images/crown.png";
import { CrownTwoTone } from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

const LandingPagesTeamLineUp = () => {
  const location = useLocation();
  const ItemState = location.state.item;
  const pathState = location.state.path;
  const [dataLineUpTeamOne, setDataLineUpTeamOne] = useState(null);
  const [dataLineUpTeamTwo, setDataLineUpTeamTwo] = useState(null);

  useEffect(() => {
    getLineUpTeamOne();
    getLineUpTeamTwo();
  }, []);

  const getLineUpTeamOne = () => {
    const payload = {
      id_team: ItemState.id_team_1,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LINE_UP_TEAM;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataLineUpTeamOne(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLineUpTeamTwo = () => {
    const payload = {
      id_team: ItemState.id_team_2,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LINE_UP_TEAM;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataLineUpTeamTwo(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Flex className="web-content-team-line-up" vertical>
      <Flex className="web-content-team-line-up-header" align="center">
        <Link to={`/${pathState}`} className="web-content-button-back">
          <LeftOutlined />
          <Text>Team Line Up</Text>
        </Link>
      </Flex>
      <Flex
        className="web-content-team-line-up-card"
        justify="center"
        wrap="wrap"
      >
        <Card
          title={
            <Meta
              avatar={<Image width={40} src={ItemState.image_team_1} />}
              title={ItemState.nama_team_1}
              description={ItemState.nama_season}
            />
          }
          bordered={false}
        >
          {dataLineUpTeamOne ? (
            dataLineUpTeamOne.map((item) => {
              return (
                <Flex
                  className="web-content-team-line-up-card-body-content"
                  justify="space-between"
                  align="center"
                >
                  <Flex
                    align="center"
                    className="web-content-team-line-up-card-body-content-player"
                  >
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      {item.isCaptain === true && <Badge
                        count={<CrownTwoTone twoToneColor={'#ffbd44'} />}
                        offset={[0, -25]} 
                        style={{
                          position: "absolute",
                          top: 0,
                          left: -10,
                          zIndex: 2,
                        }} 
                      />}
                      <Image
                        width={40}
                        src={item.image_player}
                        style={{ position: "relative", zIndex: 1 }} 
                      />
                    </div>
                    <Flex vertical>
                      <Text className="player-name">{item.nama_player}</Text>
                      <Text className="player-role">{item.role}</Text>
                    </Flex>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <a href={item.url1} target="_blank">
                      <Avatar
                        className="web-content-team-line-up-card-body-content-avatar-role"
                        src={tiktokIcon}
                        style={{ margin: "3px" }}
                      />
                    </a>
                    <a href={item.url2} target="_blank">
                      <Avatar
                        className="web-content-team-line-up-card-body-content-avatar-role"
                        src={browserIcon}
                        style={{ margin: "3px" }}
                      />
                    </a>
                    <Avatar
                      className="web-content-team-line-up-card-body-content-avatar-role"
                      src={item.image_role}
                      style={{ margin: "3px" }}
                    />
                  </Flex>
                </Flex>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Card>
        <Card
          title={
            <Meta
              avatar={<Image width={40} src={ItemState.image_team_2} />}
              title={ItemState.nama_team_2}
              description={ItemState.nama_season}
            />
          }
          bordered={false}
        >
          {dataLineUpTeamTwo ? (
            dataLineUpTeamTwo.map((item) => {
              return (
                <Flex
                  className="web-content-team-line-up-card-body-content"
                  justify="space-between"
                  align="center"
                >
                  <Flex
                    align="center"
                    className="web-content-team-line-up-card-body-content-player"
                  >
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      {item.isCaptain === true && <Badge
                        count={<CrownTwoTone twoToneColor={'#ffbd44'} />}
                        offset={[0, -25]} 
                        style={{
                          position: "absolute",
                          top: 0,
                          left: -10,
                          zIndex: 2,
                        }} 
                      />}
                      <Image
                        width={40}
                        src={item.image_player}
                        style={{ position: "relative", zIndex: 1 }} 
                      />
                    </div>
                    <Flex vertical>
                      <Text className="player-name">{item.nama_player}</Text>
                      <Text className="player-role">{item.role}</Text>
                    </Flex>
                  </Flex>
                  <Flex justify="space-between" align="center">
                    <a href={item.url1} target="_blank">
                      <Avatar
                        className="web-content-team-line-up-card-body-content-avatar-role"
                        src={tiktokIcon}
                        style={{ margin: "3px" }}
                      />
                    </a>
                    <a href={item.url2} target="_blank">
                      <Avatar
                        className="web-content-team-line-up-card-body-content-avatar-role"
                        src={browserIcon}
                        style={{ margin: "3px" }}
                      />
                    </a>
                    <Avatar
                      className="web-content-team-line-up-card-body-content-avatar-role"
                      src={item.image_role}
                      style={{ margin: "3px" }}
                    />
                  </Flex>
                </Flex>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Card>
      </Flex>
    </Flex>
  );
};

export default LandingPagesTeamLineUp;
