import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import './index.scss';
import Constant from "../../../config/constans";
import { Link } from "react-router-dom";
import { Typography, Card, Flex, List, Pagination, Image } from 'antd';
const { Meta } = Card;
const { Text, Title } = Typography;

const LandingPagesNews = () => {
  const [dataArtikel, setDataArtikel] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    getListArtikel();
  }, []);

  const getListArtikel = () => {
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_ARTIKEL;
    axios
      .get(URL)
      .then((response) => {
        if (response.status === 200) {
          setDataArtikel(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (!dataArtikel) {
    return null;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentItems = dataArtikel.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Flex vertical className="web-content-news">
      <Title level={3} className="web-content-news-title">News</Title>
      <List
        className="web-content-news-list"
        dataSource={currentItems}
        renderItem={(item) => (
          <List.Item className="web-content-news-list-item">
            <Link to={`/news/${item.id_artikel}`} state={{item}}>
              <Card
                className="web-content-news-list-card"
                hoverable
              >
                <Image preview={false} src={item.thumbnail}/>
                <Meta 
                  title={
                    <Title level={4}>{item.title}</Title>
                  }
                  description={
                    <Flex>
                      <Flex vertical>
                        <Text style={{fontWeight:"bold"}}>{item.nama_sub_kategori_game} | {moment(item.createdat).format("DD MMM YYYY HH:mm")}</Text>
                        <Text>#{item.nama_tags}</Text>
                      </Flex>
                    </Flex>
                  } />
              </Card>
            </Link>
          </List.Item>
        )}
      />
      <Pagination
        current={currentPage}
        pageSize={itemsPerPage}
        total={dataArtikel.length}
        onChange={handlePageChange}
      />
    </Flex>
  );
};

export default LandingPagesNews;
