import "./index.scss";
import { Flex, Layout, Typography, Image } from "antd";
import React, { useState } from "react";
import { Route, Routes, Link } from "react-router-dom";
import { logoAppStore, logoGooglePLay } from "../../../Assets/images";
import { AppHeader, SubHeader } from "../../../components";
import {
  LandingPagesByKategori,
  LandingPagesHighlights,
  LandingPagesHighlightsDetail,
  LandingPagesHome,
  LandingPagesNews,
  LandingPagesNewsDetail,
  LandingPagesProfile,
  LandingPagesRoomTournament,
  LandingPagesTeamLineUp,
  LandingPagesRegister,
  LandingPagesDetailRoomTournament,
  LandingPagesDetailTeam,
  LandingPagesDetailTeamSaya,
  LandingPagesLeaderboard,
  LandingPagesScoreboard,
  LandingPagesFinishRegister,
  LandingPagesDetailPlayer,
} from "../../pages";
import "./index.scss";
const { Content, Footer } = Layout;
const { Text } = Typography;

const LandingPages = (props) => {
  const [openLogin, setOpenLogin] = useState(false);

  const showDrawerLogin = () => {
    setOpenLogin(true);
  };

  const onCloseLogin = () => {
    setOpenLogin(false);
  };
  // const location = useLocation();
  // console.log(location,'location login')
  // const cat = localStorage.getItem("data");
  // console.log( cat, 'localstorage')
  // const [isLogin, setIsLogin] = useState(true);
  // const [dataPertandingan, setDataPertandingan] = useState([]);
  // const [dataKategori, setDataKategori] = useState (null);
  // const [cekdata,setcekdata] = useState(false)

  // useEffect(() => {
  //   getListKategoriGame()
  // },[]);

  // const getListKategoriGame = () => {
  //   const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_KATEGORI;
  //   axios
  //   .get(URL)
  //   .then((response) => {
  //     if (response.status === 200) {
  //       setDataKategori(response.data.Data)
  //     }
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   });
  // }

  const newData = props.dataKategori
    ? props.dataKategori.map((item) => ({
        ...item,
        path: item.nama_kategori_game.toLowerCase().replace(/\s+/g, "-"),
      }))
    : null;

  return (
    <Flex vertical className="web-score-quest-wrapper">
      <AppHeader
        openLogin={openLogin}
        showDrawerLogin={showDrawerLogin}
        onCloseLogin={onCloseLogin}
      />
      <SubHeader dataKategori={props.dataKategori} />
      <Layout>
        {/* <Sider
              className="web-sider"
              breakpoint="lg"
              collapsedWidth="0"
            >
              <div className="demo-logo-vertical" />
              <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={['4']}
                items={[UserOutlined, VideoCameraOutlined, UploadOutlined, UserOutlined].map(
                  (icon, index) => ({
                    key: String(index + 1),
                    icon: React.createElement(icon),
                    label: `nav ${index + 1}`,
                  }),
                )}
              />
            </Sider> */}
        <Layout>
          <Content className="web-content">
            <Routes>
              <Route
                exact
                path="/"
                element={<LandingPagesByKategori />}
                replace={true}
              />
              <Route
                exact
                path="/line-up"
                element={<LandingPagesTeamLineUp />}
              />
              {props.dataKategori
                ? newData.map((item) => {
                    return (
                      <Route
                        path={`/${item.path}`}
                        element={
                          <LandingPagesByKategori
                            idKategori={item.id_kategori_game}
                            path={item.path}
                            replace={true}
                          />
                        }
                      />
                    );
                  })
                : null}
              {props.dataKategori
                ? newData.map((item) => {
                    return (
                      <Route
                        path={`/${item.path}/line-up`}
                        element={<LandingPagesTeamLineUp />}
                      />
                    );
                  })
                : null}
              <Route exact path="/news" element={<LandingPagesNews />} />
              <Route
                exact
                path="/news/:id"
                element={
                  <LandingPagesNewsDetail showDrawerLogin={showDrawerLogin} />
                }
              />
              <Route
                exact
                path="/highlights"
                element={<LandingPagesHighlights />}
              />
              <Route
                exact
                path="/highlights/:id"
                element={<LandingPagesHighlightsDetail />}
              />
              <Route exact path="/profile" element={<LandingPagesProfile />} />
              <Route
                exact
                path="/room-tournament"
                element={
                  <LandingPagesRoomTournament
                    showDrawerLogin={showDrawerLogin}
                    onCloseLogin={onCloseLogin}
                  />
                }
              />
              <Route
                exact
                path="/team-saya"
                element={<LandingPagesDetailTeamSaya />}
              />
              <Route
                exact
                path="/room-tournament/:id"
                element={<LandingPagesDetailRoomTournament />}
              />
              <Route
                exact
                path="/register-tournament"
                element={<LandingPagesRegister />}
              />
              <Route
                exact
                path="/detail-team"
                element={<LandingPagesDetailTeam />}
              />
              <Route
                exact
                path="/detail-player"
                element={<LandingPagesDetailPlayer />}
              />
              <Route
                exact
                path="/leaderboard"
                element={<LandingPagesLeaderboard />}
              />
              <Route
                exact
                path="/scoreboard"
                element={<LandingPagesScoreboard />}
              />
              <Route
                exact
                path="/finish-register"
                element={<LandingPagesFinishRegister />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
      <Footer>
        <Flex justify="space-between" align="center">
          <Flex gap={20} align="center" wrap="wrap">
            <Text strong>Unduh aplikasi</Text>
            <Flex gap={20} align="center">
              <a href="https://apps.apple.com/id/app/scorequest/id6475076922">
                <Image width={120} preview={false} src={logoAppStore} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.project.scorequest&pli=1">
                <Image width={120} preview={false} src={logoGooglePLay} />
              </a>
            </Flex>
            <Flex>
              <Link to={`/privacy-policy`}>
                <Text>Privacy Policy</Text>
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Footer>
    </Flex>
  );
};

export default LandingPages;
