import { Typography } from "antd";
import React from "react";
import colors from "../../utils/colors";
const { Text } = Typography;

export default function TextCustom({
  fontWeight = "normal",
  fontSize = 12,
  color = colors.black,
  text = "",
  style,
  onClick,
}) {
  return (
    <Text style={{ fontWeight, fontSize, color, ...style }} onClick={onClick}>
      {text}
    </Text>
  );
}
