import { Flex, Input, Layout, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import HeaderMobile from "../../../components/HeaderMobile";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemScoreboard from "../../../components/ListItemScoreboard";
import ListItemScoreNonBracket from "../../../components/ListItemScoreNonBracket";
import colors from "../../../utils/colors";
import "./index.scss";
import TextCustom from "../../../components/atoms/Text";
import Constant from "../../../config/constans";
import axios from "axios";
import { useTranslation } from "react-i18next";

const LandingPagesScoreboard = () => {
  const navigate = useNavigate();
  const dataState = useLocation().state;
  const [selectTab, setSelectTab] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [dataBracket, setDataBracket] = useState([]);
  const [dataNonBracket, setDataNonBracket] = useState([]);
  const authToken = localStorage.getItem("token");
  const idUser = localStorage.getItem("idUserLogin");
  const [filteredDataSourceBracket, setFilteredDataSourceBracket] = useState(
    [],
  );
  const { t } = useTranslation();
  const [filteredDataSourceNonBracket, setFilteredDataSourceNonBracket] =
    useState([]);
  const [searchText, setSearchText] = useState("");

  const firstSelect = () => {
    if (dataState) {
      let check = itemsTab?.find((item) => item?.label == dataState?.select);
      setSelectTab(check);
    }
  };

  const getListScoreboard = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_SCOREBOARD;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataBracket(response.data.Data[0]?.scoreBracket);
          setDataNonBracket(response.data.Data[1]?.scoreNonBracket);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleSearch = (value) => {
    setSearchText(value);

    if (selectTab?.label == "Bracket") {
      const filteredData = dataBracket.filter((record) => {
        return Object.values(record).some(
          (fieldValue) =>
            fieldValue &&
            fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
        );
      });
      console.log("handleSearch", filteredData);
      setFilteredDataSourceBracket(filteredData);
    } else {
      const filteredData = dataNonBracket.filter((record) => {
        return Object.values(record).some(
          (fieldValue) =>
            fieldValue &&
            fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
        );
      });

      setFilteredDataSourceNonBracket(filteredData);
    }
  };

  const itemsTab = [
    {
      key: "1",
      label: "Bracket",
    },
    {
      key: "2",
      label: "Non Bracket",
    },
  ];

  useEffect(() => {
    firstSelect();
  }, [dataState]);

  useEffect(() => {
    getListScoreboard();
  }, []);

  return (
    <LayoutMobile>
      {/* Header */}
      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
          padding: 20,
        }}
      >
        <HeaderMobile title={t("Scoreboard")} isSearch={false} />
        <Gap height={15} />

        <section>
          <Flex gap={"large"}>
            {itemsTab?.map((item) => {
              return (
                <div>
                  <section
                    onClick={() => {
                      setSelectTab(item);
                      setSearchText("");
                    }}
                    style={{
                      borderBottom:
                        selectTab.label == item.label
                          ? `3px solid ${colors.primary1}`
                          : "",
                    }}
                  >
                    <TextCustom
                      fontSize={14}
                      fontWeight="bold"
                      color={colors.black}
                      text={item.label}
                    />
                  </section>
                </div>
              );
            })}
          </Flex>
          <Gap height={20} />
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ padding: 10 }}
            placeholder={t("SearchTournament")}
            allowClear
          />
          <Gap height={20} />

          <Flex style={{ color: colors.black, width: "100%" }}>
            {selectTab?.label == "Bracket" ? (
              <Flex
                vertical
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  height: "50vh",
                }}
              >
                {searchText ? (
                  filteredDataSourceBracket?.map((item) => {
                    return <ListItemScoreboard item={item} />;
                  })
                ) : dataBracket?.length ? (
                  dataBracket?.map((item) => {
                    return <ListItemScoreboard item={item} />;
                  })
                ) : (
                  <Flex
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextCustom fontSize={12} text={t("DataNotFound")} />
                  </Flex>
                )}
              </Flex>
            ) : (
              <Flex
                vertical
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  height: "50vh",
                }}
              >
                {searchText ? (
                  filteredDataSourceNonBracket?.map((item) => {
                    return <ListItemScoreNonBracket item={item} />;
                  })
                ) : dataNonBracket?.length ? (
                  dataNonBracket?.map((item) => {
                    return <ListItemScoreNonBracket item={item} />;
                  })
                ) : (
                  <Flex
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TextCustom fontSize={12} text={t("DataNotFound")} />
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </section>
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesScoreboard;
