const colors = {
  primary1: "#FF5722",
  primary2: "#62208D",
  secondary1: "#f5f5f5",
  secondary2: "#758694",
  black: "rgba(0, 0, 0, .8)",
  white: "#fff",
  red: "red",
};

export default colors;
