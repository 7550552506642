import { Flex, Image } from "antd";
import React from "react";
import pending from "../../Assets/images/pending.png";
import approve from "../../Assets/images/approve.png";
import decline from "../../Assets/images/decline.png";
import add from "../../Assets/images/tournament/icon/add.png";
import mvp from "../../Assets/images/tournament/icon/mvp.png";
import trash from "../../Assets/images/tournament/icon/trash.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import TextCustom from "../atoms/Text";
import colors from "../../utils/colors";
import "./index.scss";
import { useNavigate } from "react-router-dom";

// type = trash, add, role, player
export default function ListItemMember({
  item,
  type = "trash",
  onClickIcon,
  dataDetailTournament,
  isFromMyTeam,
}) {
  const navigate = useNavigate();
  const Icon = () => {
    switch (type) {
      case "mvp":
        return mvp;
      case "add":
        return add;
      case "player":
        if (item?.is_approved == "1") {
          return pending;
        } else if (item?.is_approved == "2") {
          return approve;
        } else {
          return decline;
        }
      case "trash":
        return trash;

      default:
        return trash;
    }
  };
  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
      }}
    >
      <Flex
        onClick={() =>
          navigate("/detail-team", {
            state: {
              dataState: item,
              isFromMyTeam: isFromMyTeam,
            },
          })
        }
        align="center"
        justify="space-between"
        gap={"small"}
      >
        <Image
          preview={true}
          src={item?.image_team}
          style={{ borderRadius: 50, height: 50, width: 50 }}
        />
        <Flex vertical>
          <TextCustom
            fontSize={12}
            fontWeight="bold"
            color={colors.black}
            text={item?.nama_team}
          />
          <TextCustom
            fontSize={12}
            color={colors.black}
            text={item?.deskripsi_team}
          />
        </Flex>
      </Flex>
      <Flex>
        {dataDetailTournament?.champions == item?.id_team && (
          <div
            style={{
              backgroundColor: colors.primary1,
              position: "absolute",
              right: 0,
              top: 0,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
              padding: 4,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <TextCustom fontSize={12} color={colors.white} text="Champions" />
          </div>
        )}
        {type && (
          <Image
            preview={false}
            src={Icon()}
            onClick={onClickIcon}
            // onClick={() => onClickIcon(item)}
            style={{
              height: 25,
              width: 25,
              marginTop: type == "mvp" || type == "player" ? 20 : 0,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}
