const Constant = {
  // URL_MASTER_PATH: 'http://104.248.150.29/',
  URL_MASTER_PATH: "https://be.scorequest.id/",
  // URL_MASTER_PATH: "http://152.42.174.2/",

  // Register
  URL_POST_REGISTRATION_USER: "user/register",

  // Profile
  URL_POST_DETAIL_USER: "api/v1/user/detail",
  URL_UPDATE_DETAIL_USER: "api/v1/user/update",

  // Chnage Password
  URL_POST_CHANGE_PASSWORD: "api/v1/user/updatePassword",

  // Login
  URL_POST_LOGIN_USER: "user/login",

  // Forgot Password
  URL_POST_FORGOT_PASSWORD: "user/forgotpassword",

  // Kategori
  URL_GET_LISTS_KATEGORI: "user/listKategoriGame",
  URL_POST_KOMENTAR: "api/v1/komentar/add",

  // Sub Kategori
  URL_GET_LISTS_SUB_KATEGORI_BY_KATEGORI: "user/listSubKategoriGameByKategori",

  // Jadwal Pertandingan
  URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI: "jadwal/listbykategori",
  URL_POST_LISTS_JADWAL_PERTANDINGAN_BY_KATEGORI_AND_SUB_KATEGORI:
    "jadwal/listbykategoriandsub",
  URL_GET_LIST_ALL_JADWALPERTANDINGAN: "jadwal/listtimeline",

  // Line Up Team
  URL_GET_LINE_UP_TEAM: "user/listLineUpByTeamUser",

  // Article
  URL_GET_LIST_ARTIKEL: "artikel/list",
  URL_POST_LISTS_ARTIKEL_WITH_COMMENT: "artikel/detailwithkomentar",

  // Video
  URL_GET_LIST_VIDEO: "user/listVideo",

  // Tournament
  URL_GET_LISTS_TOURNAMENT: "api/v1/tournament/allroom",
  URL_GET_LISTS_TOURNAMENT_BERLANGSUNG: "api/v1/tournament/allroomBerlangsung",
  URL_POST_ADD_TOURNAMENT: "api/v1/daftar/tournament",
  URL_POST_DETAIL_TOURNAMENT: "api/v1/tournament/detail",
  URL_GET_LIST_PESERTA_TOURNAMENT: "api/v1/tournament/listPeserta",
  URL_GET_LIST_MAX_PESERTA_TOURNAMENT: "api/v1/admin/getMaxMemberByIdGame",

  // User
  URL_GET_LISTS_USER: "api/v1/user/list",
  URL_GET_LISTS_ROLE: "api/v1/admin/listRole",
  URL_GET_LISTS_ROLE_BEFORE_LOGIN: "admin/listRole",

  // Team
  URL_GET_LISTS_TEAM: "api/v1/team",
  URL_POST_DETAIL_TEAM: "api/v1/team/detailTeam",
  URL_POST_UPDATE_TEAM: "api/v1/team/updateTeam",
  URL_POST_DELETE_TEAM: "api/v1/team/deleteTeam",
  URL_POST_DETAIL_PLAYER: "api/v1/player/detail",

  // Team Saya
  URL_POST_DETAIL_TEAM_SAYA: "api/v1/player/detailAccountUser",

  // Scoreboard
  URL_GET_LIST_SCOREBOARD_BRACKET: "user/listLeaderBoardBracket",
  URL_GET_LIST_SCOREBOARD_NON_BRACKET: "user/listLeaderBoardNonBracket",
  URL_GET_LIST_SCOREBOARD: "score/listScoreBoard",

  //Leaderboard
  URL_GET_LIST_LEADERBOARD_TEAM: "user/listLeaderBoardTeam",
  URL_GET_LIST_LEADERBOARD_PLAYER: "user/listLeaderBoardPlayer",
};

export default Constant;
