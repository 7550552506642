import React from 'react';
import './index.scss';

import { Menu, Image, Flex, Typography } from 'antd';
import { Link } from 'react-router-dom';
const { Text } = Typography;

function SubHeader(props) {
  const dataKategori = Array.isArray(props.dataKategori) ? props.dataKategori : [];
  const newData = dataKategori.map(item => ({ ...item, path: item.nama_kategori_game.toLowerCase().replace(/\s+/g, '-') }));
  // console.log(newData, 'datakategori');

  return (
    <>
      <Menu mode="horizontal" className='web-sub-header'>
        {newData.map(item => (
          item.id_kategori_game === "450e1001-f3a7-4ee0-8f4c-fd35ccdd95c3" ? null :
          <Menu.Item key={item.id_kategori_game} className='web-sub-header-list'>
            <Link to={`/${item.path}`}>
              <Flex horizontal align='center'>
                <Image
                  preview={false}
                  width={20}
                  src={item.image_kategori_game}
                />
                <Text strong style={{ marginLeft: '10px' }} className='web-sub-header-list-title'>{item.nama_kategori_game}</Text>
              </Flex>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </>
  );
}

export default SubHeader;
