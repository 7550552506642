import React from "react";
import './index.scss';
import { LeftOutlined } from '@ant-design/icons';

import { Flex, Typography } from "antd";

import { Link } from "react-router-dom";
const { Text, Title } = Typography;


const PrivacyPolicy = () => (
    <Flex vertical className="privacy-policy-wrapper">
        <Link to={`/`} className="button-back"><LeftOutlined />
            <Text>Kembali ke Home</Text>
        </Link>
        <Flex vertical align="center">
            <Flex vertical className="privacy-policy-content">
                <Title level={4}>PRIVACY POLICY :</Title>
                <text>Namina Creation atau yang lebih dikenal dengan NATION merupakan lini usaha yang berada dalam PT Namina Kita Teknologi. Kini Mobile Nation hadir untuk memberikan layanan yang lebih menyeluruh untuk Anda.</text>
                <ul className="list-with-labeling">
                    <li>
                        <span>Data apa saja yang kita butuhkan dari audiens</span>
                    </li>
                    <li>
                        <span>Kegiatan apa saja yang membutuhkan data</span>
                    </li>
                    <li>
                        <span>Apakah kita memiliki user?</span>
                    </li>
                </ul>
                <Title level={4}>DEFINISI</Title>
                <ol type="1">
                    <li>Platform Mobile Nation (selanjutnya disebut “Platform”) adalah portal berita yang dioperasikan melalui Web, Mobile Web, dan Mobile Aplikasi yang sebagian Kontennya berasal dari dan dikelola oleh pengguna Internet.</li>
                    <li>
                        Platform Mobile Nation mencakup:<br/>
                        Web & Mobile Web: <a href="https://mobilenation.id/">https://mobilenation.id/</a><br/>
                        Mobile Aplikasi: Score Quest.
                    </li>
                    <li>Hak cipta dan merek dagang Platform dimiliki sepenuhnya oleh PT. Namina Kita Teknologi serta afiliasinya.</li>
                    <li>User adalah sebutan bagi pengguna Platform yang melakukan registrasi di Platform dan memiliki akun di Platform.</li>
                    <li>User memperoleh akses untuk menggunakan fitur dan layanan Platform.</li>
                    <li>Konten adalah materi yang ditempatkan (posted) ke dalam sistem dan/atau ditayangkan di situs Platform, terdiri dari teks, file foto, video, dan audio, serta komentar oleh User. Konten juga termasuk materi yang diunggah (uploaded), ditautkan (linked) atau dilekatkan (embedded) ke dalam materi yang ditayangkan di Platform.</li>
                    <li>Aktifitas User adalah menulis artikel, membaca artikel, mengikuti kuis pada Platform.</li>
                    <li>Platform dapat setiap saat mengganti, menambah atau mengurangi Syarat dan Ketentuan ini tanpa pemberitahuan sebelumnya. User terikat oleh setiap perubahan tersebut.</li>
                    <li>Setiap perubahan Syarat & Ketentuan akan diberitahukan kepada User melalui email.</li>
                    <li>User bertanggung jawab secara pribadi dan penuh serta setuju untuk membebaskan dan tidak membebani Platform dan afiliasinya atas segala terjadinya tindakan penyalahgunaan atas konten yang ditulisnya dan ditayangkan di Platform dan bersedia untuk membebaskan Platform dan afiliasinya dari segala bentuk permasalahan hukum yang timbul sebagai akibat dari pemuatan Konten di Platform.</li>
                    <li>User setuju untuk membebaskan serta tidak membebani Platform dan afiliasinya selaku pihak yang menaunginya atas segala keluhan, protes, klaim, gugatan hak cipta, atau permasalahan hukum yang timbul atas konten yang ditulis dan ditayangkan oleh User di Platform.</li>
                    <li>Platform dan afiliasinya menjamin perlindungan data User.</li>
                    <li>Platform dan afiliasinya tidak dapat dituntut dan dimintai tanggung jawab hukum atas setiap kekeliruan, kesalahan, atau ketidaktepatan pernyataan dan informasi di dalam Konten yang ditulis dan ditayangkan User.</li>
                    <li>Platform dan afiliasinya tidak bertanggung jawab atas akibat langsung atau tidak langsung dari penayangan segala bentuk Konten oleh dan/atau dari User di Platform.</li>
                    <li>User bertanggung jawab secara pribadi dan penuh atas setiap isi komentar dan Konten yang dibagikan ke berbagai situs portal berita dan setuju untuk membebaskan serta tidak membebani Platform dan afiliasinya atas segala terjadinya tindakan penyalahgunaan dan segala bentuk permasalahan hukum yang timbul sebagai akibat pemuatan komentar dan konten yang dibagikan ke berbagai situs portal berita.</li>
                    <li>
                        Platform dan afiliasinya berhak untuk melakukan tindakan kepada User atas apa yang User lakukan di Platform dan afliasinya, antara lain:
                        <ul>
                            <li>Platform dan afiliasinya berhak untuk melakukan suspend account apabila User memberikan hate speech dan/atau di-report oleh User lainnya;</li>
                            <li>Platform dan afiliasinya berhak untuk melakukan banned account apabila User melanggar UU ITE;</li>
                            <li>Platform dan afiliasinya berhak untuk melakukan inactive account apabila ada permintaan menghapus akun dari User. Masa tenggang untuk inactive account adalah 30 (tiga puluh) hari sejak di-nonaktifkannya akun. Apabila dalam kurun waktu 30 (tiga puluh) hati akun tidak diaktifkan kembali, maka secara otomatis akun tidak dapat diakses lagi.</li>
                            <li>Platform dan afiliasinya berhak sepenuhnya untuk melaporkan User atas UU ITE kepada pihak yang berwajib apabila ditemukan konten dan/atau aktifitas yang melanggar UU ITE.</li>
                            <li>Platform dan afiliasinya berhak sepenuhnya untuk melaporkan User kepada pihak yang berwajib apabila ada kecurigaan terkait bagian dari organisasi terlarang dan organisasi atau individu teroris.</li>
                            <li>Platform dan afiliasinya berhak untuk menyampaikan segala informasi terkait pemilik Konten, termasuk, bila ada, alamat Internet Protocol (IP), dan Device Identifiers yang terekam di dalam sistem Platform, kepada pejabat berwenang (Penyidik, Penyidik Pembantu, Penyelidik, Jaksa Penuntut Umum, Hakim) yang disertai dengan permohonan tertulis dari Kepolisian Republik Indonesia.</li>
                        </ul>
                    </li>
                    <li>Gugatan terkait penggunaan konten (teks/foto/video) dan materi apapun yang tidak sesuai dengan Ketentuan Layanan dan peraturan perundang-undangan yang berlaku sepenuhnya menjadi tanggung jawab User yang mengunggahnya. Platform dan afiliasinya dibebaskan dari segala tuntutan dan gugatan atas penggunaan teks/foto/video tersebut.</li>
                    <li>
                        Dalam rangka menjaga Konten dari pelanggaran Syarat dan Ketentuan Platform maupun peraturan perundang-undangan yang berlaku, Platform menyediakan fitur Pelaporan Konten dengan ketentuan sebagai berikut:
                        <ol type="a">
                            <li>Pengelola tidak melakukan pengawasan terhadap Konten sepanjang waktu. User dan Pembaca dapat ikut serta mengawasi dan memonitor Konten di Platform dengan menggunakan fitur pelaporan Konten (teks, gambar/foto, video) dan Komentar</li>
                            <li>User dan Pembaca juga bisa melaporkan Konten yang dikirimkan oleh User yang dianggap mengganggu kenyamanan dan/atau melanggar Ketentuan Layanan dengan menggunakan fitur 'Laporkan User' yang tersedia di setiap Halaman Profil User.</li>
                            <li>Fitur Laporan di Platform hanya boleh digunakan oleh User dan Pembaca untuk berpartisipasi melakukan pengawasan dan pengontrolan, bukan untuk tujuan lain. Platform akan memberikan teguran terhadap penyalahgunaan fitur tersebut.</li>
                            <li>
                                Platform akan mempelajari setiap laporan yang masuk dan berhak mengambil tindakan serta menerapkan sanksi yang diperlukan berupa namun tidak terbatas pada:
                                <ul>
                                    <li>Peringatan Tertulis;</li>
                                    <li>Penghapusan Konten; dan/atau</li>
                                    <li>Pemblokiran Akun Platform.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ol>
                <Title level={4}>HAK CIPTA</Title>
                <ol type="1">
                    <li>User tidak diperkenankan menggunakan sebagian atau seluruh rancangan produk Platform untuk tujuan apapun tanpa seizin Platform.</li>
                    <li>User tidak diperkenankan untuk memodifikasi, menyalin, mengubah atau menambah rancangan produk Platform dalam keadaan atau kondisi apapun.</li>
                    <li>User, Pembaca atau Pengakses Platform tidak diperkenankan mengambil, mengunduh, menautkan dan/atau melekatkan Konten tanpa mencantumkan nama pemilik Konten berikut sumbernya seperti tercantum pada alamat URL Konten.</li>
                    <li>Penggunaan Konten di Platform untuk keperluan komersial hanya boleh dilakukan atas seizin User dan pihak yang memiliki Konten tersebut.</li>
                    <li>Penggunaan nama, logo dan/atau atribut Platform lainnya untuk tujuan apapun harus atas seizin Platform.</li>
                    <li>Seluruh rancangan produk Platform, termasuk namun tidak terbatas meliputi tulisan, desain, gambar, audio, video serta kode pemrograman di Platform adalah hak cipta milik PT. Namina Kita Teknologi</li>
                    <li>Setiap Konten milik User yang ditempatkan dan/atau ditayangkan di Platform, sepanjang tidak ada laporan sebaliknya dari orang atau pihak lain, sepenuhnya menjadi milik User yang menempatkan dan/atau menayangkannya.</li>
                    <li>Konten milik orang atau pihak lain yang ditempatkan dan/atau ditayangkan di Platform atau diunduh (uploaded), ditautkan (linked) atau dilekatkan (embed) ke dalam materi yang ditayangkan di Platform, hak ciptanya tetap menjadi miliknya. Risiko terkait penggunaan konten milik orang atau pihak lain ini sepenuhnya menjadi tanggung jawab User yang menempatkan atau menayangkannya di Platform.</li>
                    <li>Platform mendapat izin untuk menawarkan penggunaan Konten ke pihak lain, namun penggunaannya tetap atas seizin pemilik Konten.</li>
                    <li>Platform dan afiliasinya dibebaskan dari segala tuntutan dan pertanggungjawaban atas pelanggaran hak antara lain yang berkaitan dengan Hak Kekayaan Intelektual (HAKI) yang dilakukan oleh pihak ketiga terhadap Konten yang ditempatkan dan/atau ditayangkan di Platform</li>
                </ol>
                <Title level={4}>PERSYARATAN KONTEN</Title>
                <text>Konten yang akan dibuat dan ditayangkan di Platform harus sesuai dan tidak bertentangan dengan kesusilaan, ketertiban umum, Syarat dan Ketentuan Platform, dan peraturan perundang-undangan yang berlaku di wilayah Negara Republik Indonesia. Oleh karena itu, User diwajibkan untuk mematuhi hal-hal berikut ini:</text>
                <ol type="1">
                    <li>Sebelum menempatkan dan/atau menayangkan Konten, User wajib memastikan bahwa Konten tersebut tidak melanggar salah satu ketentuan yang tertulis pada Syarat dan Ketentuan.</li>
                    <li>
                        Saat menayangkan Konten di Platform, User tidak diperbolehkan untuk:
                        <ol type="a">
                            <li>Menjiplak atau menyalin-tempel (copy-paste) sebagian atau seluruh artikel milik orang atau pihak lain tanpa maksud menciptakan artikel baru yang dapat dianggap sebagai artikel karyanya sendiri. Menyertakan kutipan dari sumber lain diperbolehkan, sepanjang tidak lebih dari 25 persen dari total keseluruhan Konten.</li>
                            <li>Melakukan aksi plagiarisme dalam bentuk dan untuk alasan apapun, termasuk menggunakan dan/atau mengutip sebagian atau seluruh karya dalam bentuk teks, foto dan video milik orang lain serta hal-hal lain yang serupa, seolah-olah itu miliknya, tanpa dengan jelas dan tegas menyebutkan sumber data dan informasinya secara tepat dan memadai.</li>
                            <li>Menayangkan-ulang (reposting) Konten yang sama persis yang pernah ditayangkan sebelumnya di Platform.</li>
                            <li>Menayangkan potongan atau cuplikan artikel dengan maksud mengarahkan pengunjung ke sumber asli artikel di luar Platform.</li>
                            <li>Memuat dan/atau menayangkan artikel berisi iklan, promosi, atau semacamnya yang secara langsung atau tidak langsung atau dalam bentuk tautan (link) berisi ajakan untuk membeli produk barang atau jasa yang dimaksud dalam artikel.</li>
                        </ol>
                    </li>
                    <li>
                        Saat menayangkan foto, ilustrasi, referensi, atau konten pelengkap lain, User wajib untuk:
                        <ol type="a">
                            <li>Menyebutkan sumber (nama orang atau pihak pencipta, pemilik, atau pemegang hak cipta) secara langsung atau melalui tautan menuju alamat sumber.</li>
                            <li>Menyebutkan sumber lengkap dan jelas untuk Konten hasil pencarian dari mesin pencari (tidak dibenarkan hanya menyebut nama/merek mesin pencari sebagai sumbernya).</li>
                        </ol>
                    </li>
                    <li>Sebelum menayangkan Konten, User wajib memilih kategori atau sub-kategori yang sesuai dengan isi, tema, atau konteks Konten.</li>
                    <li>User dilarang mengirim komentar berupa atau dimaksudkan sebagai pesan berantai (spamming), dengan/atau tanpa disertai tautan tertentu.</li>
                    <li>
                        Terhadap Konten yang telah ditempatkan ke dalam sistem Platform, User mengizinkan Pengelola untuk:
                        <ol type="a">
                            <li>Menghapus sebagian atau keseluruhan Konten, pesan, foto, gambar, dan/atau komentar yang melanggar Syarat dan Ketentuan berdasarkan atau tanpa aduan User atau pengguna internet dengan atau tanpa pemberitahuan sebelumnya kepada Akun pemilik Konten.</li>
                            <li>Menempatkan Foto sebagai ilustrasi atau pelengkap Konten untuk keperluan penayangan Artikel Utama (headline).</li>
                            <li>Mengoreksi kekeliruan kode-kode HTML, termasuk mengoreksi penggunaan jenis huruf dan ukurannya, yang dapat mengganggu tampilan Konten maupun Platform secara keseluruhan.</li>
                            <li>Menyunting Label, Jenis dan Kategori Konten.</li>
                            <li>Menyimpan segala aktifitas User pada saat menggunakan Platform untuk kebutuhan Product Development Platform dan afiliasinya.</li>
                        </ol>
                    </li>
                    <li>User dengan kesadaran dan kemauan sendiri menempatkan dan menayangkan Konten, dan bersedia bertanggung jawab penuh atas seluruh Konten yang ditayangkan.</li>
                    <li>User dilarang menyerang, menghina, dan/atau menjatuhkan karakter atau pribadi User lain dengan cara dan tujuan apapun.</li>
                    <li>User dilarang memperdebatkan dan/atau mempertentangkan ajaran agama tertentu, meliputi keyakinan dan ritual keagamaan.</li>
                    <li>User dilarang menempatkan Konten apapun dengan cara apapun yang dapat merusak, melumpuhkan, membebani atau mengganggu server atau jaringan Platform.</li>
                    <li>
                        Dengan mendaftar di Platform, User memahami dan setuju untuk tidak menggunakan, menempatkan, mengunduh, menautkan, melekatkan dan atau menayangkan Konten yang:
                        <ol type="a">
                            <li>Melanggar atau menyalahi hak orang lain, termasuk tanpa kecuali, hak intelektual, hak paten, merek dagang, rahasia dagang, hak cipta, publisitas atau hak milik lainnya dari pihak ketiga.</li>
                            <li>Mengancam keselamatan, memfitnah, mencemarkan nama baik, menipu, mencurangi, dan/atau menimbulkan kebencian pada individu atau kelompok tertentu.</li>
                            <li>Memuat dan/atau berisi informasi palsu atau yang diragukan kebenarannya secara sengaja dengan maksud untuk menipu, membohongi atau memperdaya pembaca Platform.</li>
                            <li>Menghina, menyinggung, melecehkan, merendahkan, mengintimidasi, memicu pertentangan dan/atau permusuhan individu atau kelompok berdasarkan Suku, Agama, Ras dan Antar Golongan (SARA), jenis kelamin, orientasi seksual, usia, atau cacat fisik.</li>
                            <li>Melanggar norma kesusilaan, mengandung unsur cabul dan pornografi</li>
                            <li>Menganjurkan atau menyarankan perbuatan yang melanggar hukum.</li>
                            <li>Berisi kata-kata sumpah serapah, gambar, atau bentuk grafis lainnya yang berisi dan/atau menimbulkan rasa takut, kasar, kotor, dan jijik.</li>
                            <li>Menyebarkan ideologi atau ajaran tertentu yang melanggar hukum yang berlaku di wilayah Republik Indonesia.</li>
                            <li>Mengandung virus atau kode komputer lainnya, file atau program yang dapat mengganggu, merusak atau membatasi fungsi dari perangkat lunak (software) atau perangkat keras (hardware) komputer atau peralatan komunikasi, atau memperbolehkan penggunaan komputer atau jaringan komputer yang tidak sah.</li>
                            <li>Melanggar Syarat dan Ketentuan, petunjuk atau kebijakan lainnya yang ada di Platform.</li>
                        </ol>
                    </li>
                    <li>User tidak dibenarkan dan tidak berhak mengafiliasikan dirinya dan/atau menggunakan nama Platform pada saat mengumpulkan, melaporkan, mengolah ataupun mempublikasikan suatu fakta kejadian atau peristiwa. Nama Platform hanya bisa disebut sebagai Platform Blog yang semua Kontennya berasal dari User.</li>
                    <li>Platform tidak mengeluarkan surat izin, surat keterangan, surat penugasan dan/atau surat kuasa kepada siapapun atau pihak manapun dalam rangka mengumpulkan, mengolah, melaporkan dan menyebarluaskan informasi, kejadian atau peristiwa.</li>
                    <li>Platform dibebaskan dari segala tuntutan dan pertanggungjawaban atas segala hal terkait Konten User.</li>
                </ol>
                <text>Platform tunduk kepada peraturan dan ketentuan hukum yang berlaku di Republik Indonesia. Oleh karena itu, saat terjadi keluhan, protes, klaim dan/atau permasalahan hukum yang disebabkan oleh Konten yang dimasukkan dan/atau ditayangkan oleh User, Platform berhak menyampaikan segala informasi terkait pemilik Konten, termasuk, bila ada, alamat Internet Protocol (IP), dan Device Identifiers yang terekam di dalam sistem Platform, kepada pejabat berwenang (Penyidik, Penyidik Pembantu, Penyelidik, Jaksa Penuntut Umum, Hakim) yang disertai dengan permohonan tertulis dari Kepolisian Republik Indonesia.</text>
                <ul>
                    <li>
                        <em>User Generated Content / UGC (Konten Buatan Pengguna/Pembaca), termasuk Komentar, Testimoni dan Ulasan</em><br/>
                        Kami menawarkan Anda untuk terlibat dalam aktivitas publik, seperti mengirimkan tulisan, ulasan, testimoni, dan berkomentar pada artikel di situs kami. Informasi yang Anda kirimkan dapat dilihat publik, dikumpulkan, dan digunakan oleh pengguna lain dari layanan tersebut.
                    </li>
                    <li>
                        <em>Pemasangan Iklan Personal atau Ritel</em><br/>
                        Mobile Nation memiliki layanan iklan personal atau ritel. Anda dapat mempublikasikan barang dan jasa Anda melalui media-media Namina Creation. Pemasangan iklan dapat dilakukan dengan menghubungi kami melalui nomor telepon yang tersedia atau email.
                    </li>
                    <li>
                        <em>Keikutsertaan Kegiatan Online dan Offline</em><br/>
                        Saat Anda mengikuti kegiatan online atau offline dari Namina Creation, baik yang dilakukan sendiri maupun bersama pihak ketiga, Anda akan mengisi data pribadi saat melakukan pendaftaran. Pendaftaran dapat dilakukan secara online melalui platform yang telah kami sediakan.
                    </li>
                    <li>Kami mengumpulkan, menggunakan, atau memproses informasi pribadi untuk pengembangan layanan yang lebih baik dari Namina Creation untuk Anda. Dari layanan ini, Anda juga akan mendapatkan update terbaru seputar acara dan berita dari Namina Creation.</li>
                </ul>
                <text>Silahkan hubungi kami jika audience/pembaca menemukan masalah seputar privacy, kelayakan konten dan hak cipta yang ada pada platform kami.</text><br/>
                <text>Email: Hello@anaknation.id</text>
                <text>Phone: +62 811 1620888</text>
            </Flex>
        </Flex>
    </Flex>
  
);
export default PrivacyPolicy;
