import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import './index.scss';
import { LeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Constant from "../../../config/constans";
import { Link, useLocation } from "react-router-dom";
import { Empty, Typography, Flex, List, Image, Divider, Button, Form, Input, Row, Col, Modal  } from 'antd';
const { TextArea } = Input;
const { Text, Title } = Typography;

const LandingPagesNewsDetail = ({ showDrawerLogin }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const itemState = location.state.item;
  const getIdUser = localStorage.getItem("idUserLogin");
  const getTokenUser = localStorage.getItem("token");
  const getStatusLogin = localStorage.getItem("statusLogin");
  const [dataArtikelWithKomentar, setDataArtikelWithKomentar] = useState(null);
  const [dataKomentar, setDataKomentar] = useState(null);
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    getListArtikelWithKomentar();
  }, [dataKomentar]);

  const getListArtikelWithKomentar = () => {
    const payload = {
      id_artikel: itemState.id_artikel,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_LISTS_ARTIKEL_WITH_COMMENT;
    axios
      .post(URL, payload)
      .then((response) => {
        if (response.status === 200) {
          setDataArtikelWithKomentar(response.data.Data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addKomentarNews = (value) => {
    const headers = {
      "Authorization": `Bearer ${getTokenUser}`
    };
    const payload = {
      komentar: value.komentar,
      id_user: getIdUser,
      id_artikel: dataArtikelWithKomentar[0].id_artikel
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_KOMENTAR;
    axios
      .post(URL, payload, {headers})
      .then((response) => {
        if (response.status === 200) {
          setDataKomentar(response.data.Data)
          form.resetFields();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirm = () => {
    modal.confirm({
      title: 'Permintaan Login',
      icon: <ExclamationCircleOutlined />,
      content: 'Silahkan login terlebih dahulu.',
      onOk: () => {showDrawerLogin()},
      okText: 'Login',
      cancelText: 'Batal',
    });
  };

  const validationValues = () => {
    modal.confirm({
      title: 'Peringatan!!!',
      icon: <ExclamationCircleOutlined />,
      content: 'Silahkan isi komentar',
      cancelText: 'Batal',
    });
  };

  const onFinishKomentar = () => {
    const values = form.getFieldsValue();
    if (!getStatusLogin) {
      confirm()
      return
    }
    if (getStatusLogin && !values.komentar) {
      validationValues()
      return
    }
    console.log(values)
    addKomentarNews(values)
  };

  return (
    <>
      <Link to={`/news`} className="web-content-button-back"><LeftOutlined />
        <Text>Kembali</Text>
      </Link>
      <Flex vertical justify="center" align="center">
      {dataArtikelWithKomentar ? 
        dataArtikelWithKomentar.map( (item) => 
          <Flex vertical className="web-content-news-detail">
            <Title level={1} className="web-content-news-detail-title">{item.title}</Title>
            <Flex vertical className="web-content-news-detail-tags">
              <Text className="web-content-news-detail-tags-kategori-with-date">{moment(item.createdat).format("DD MMM YYYY HH:mm")}</Text>
              {/* <Text className="web-content-news-detail-tags-kategori-with-date">{item.nama_kategori_game} | {item.nama_sub_kategori_game} | {moment(item.createdat).format("DD MMM YYYY HH:mm")}</Text> */}
              <Text className="web-content-news-detail-tags-item">#{item.tags}</Text>
            </Flex>
            <Image className="web-content-news-detail-tags-banner" preview={false} src={item.image_header} />
            <div className="web-content-news-detail-artikel" dangerouslySetInnerHTML={{ __html: item.artikel }} style={{color:"#000000"}}/>
            <Divider>
              <Title level={3} className="web-content-news-title-komentar">Komentar</Title>
            </Divider>
            <List
              className="web-content-news-detail-list-komentar"
              dataSource={item.list_komentar || []} // Ensure dataSource is always an array
              renderItem={(commentItem) => 
                <List.Item>
                  <Text className="name-user-with-date">{moment(commentItem.createdat).format("DD MMM YYYY HH:mm")} | {commentItem.nama_user}</Text>
                  <Text className="komentar">{commentItem.komentar}</Text>
                </List.Item>
              }
            />
            <Form
              layout="vertical" 
              form={form}
              onFinish={onFinishKomentar} 
              className='web-content-news-write-komentar'
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item 
                    label={<Title level={4}>Komentar</Title>}
                    name="komentar"
                    rules={[
                      {
                        message: 'Silahkan Masukkan Komentar',
                      },
                    ]}
                  >
                    <TextArea rows={4} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Flex justify="flex-end" className='web-content-news-write-komentar-button'>
                    <Button type="primary" htmlType='submit'>Kirim</Button>
                  </Flex>
                </Col>
              </Row>
            </Form>
            
          </Flex>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      </Flex>
      {contextHolder}
    </>
  );
};

export default LandingPagesNewsDetail;
