import {
  Button,
  Card,
  Flex,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import people from "../../../Assets/images/tournament/icon/people.png";
import tournament from "../../../Assets/images/tournament/tournament.png";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import HeaderMobile from "../../../components/HeaderMobile";
import ListItemTournament from "../../../components/ItemListTournament";
import ListItemScoreBracket from "../../../components/ListItemScoreBracket";
import ListItemScoreNonBracket from "../../../components/ListItemScoreNonBracket";
import LayoutMobile from "../../../components/LayoutMobile";
import colors from "../../../utils/colors";
import "./index.scss";
import { useEffect } from "react";
import Constant from "../../../config/constans";
import axios from "axios";
import dayjs from "dayjs";
import ItemListTournamentLive from "../../../components/ItemListTournamentLive";
import ListItemScoreboard from "../../../components/ListItemScoreboard";
import { useTranslation } from "react-i18next";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const { Meta } = Card;
const { Text, Title } = Typography;

const LandingPagesRoomTournament = ({ showDrawerLogin, onCloseLogin }) => {
  const navigate = useNavigate();
  const [dataListTournament, setDataListTournament] = useState([]);
  const [dataListTournamentLive, setDataListTournamentLive] = useState([]);
  const [isModalSearch, setIsModalSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataBracket, setDataBracket] = useState([]);
  const [dataNonBracket, setDataNonBracket] = useState([]);
  const authToken = localStorage.getItem("token");
  const { t } = useTranslation();
  const [modal, contextHolderModal] = Modal.useModal();
  const getStatusLogin = localStorage.getItem("statusLogin");

  const getListTournament = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_TOURNAMENT;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          setDataListTournament(data);

          let tmpItem = [];
          data?.map((item) => {
            const startDate = dayjs(item.start_date);
            const currentTime = dayjs();
            let diffDate = parseInt(
              Math.abs(startDate.diff(currentTime, "week", true)),
            );
            if (!diffDate) {
              tmpItem.push(item);
            }
          });
          // setDataListTournamentLive(tmpItem);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  const getListTournamentBerlangsung = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_TOURNAMENT_BERLANGSUNG;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data || [];
          setDataListTournamentLive(data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleModalSearch = () => {
    setIsModalSearch(!isModalSearch);
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataListTournament.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };

  const RenderListSearch = () => {
    if (searchText) {
      return filteredDataSource?.map((item, index) => {
        return (
          <ListItemTournament
            key={index}
            item={item}
            id={item?.id_tournament}
          />
        );
      });
    }
    return dataListTournament?.map((item, index) => {
      return (
        <ListItemTournament key={index} item={item} id={item?.id_tournament} />
      );
    });
  };

  const getListScoreboard = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_SCOREBOARD;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataBracket(response.data.Data[0]?.scoreBracket);
          setDataNonBracket(response.data.Data[1]?.scoreNonBracket);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const confirm = () => {
    modal.confirm({
      title: "Permintaan Login",
      icon: <ExclamationCircleOutlined />,
      content: "Silahkan login terlebih dahulu.",
      onOk: () => {
        showDrawerLogin();
      },
      okText: "Login",
      cancelText: "Batal",
    });
  };

  useEffect(() => {
    if (!getStatusLogin) {
      // confirm();
      showDrawerLogin();
    } else {
      getListTournament();
      getListTournamentBerlangsung();
      getListScoreboard();
    }
  }, []);

  if (!getStatusLogin) return <>{contextHolderModal}</>;

  return (
    <LayoutMobile>
      {/* Header */}
      {contextHolder}
      {contextHolderModal}

      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
        }}
      >
        <div style={{ padding: 20 }}>
          <HeaderMobile
            title={"Room Tournament"}
            isSearch={true}
            onClickSearch={handleModalSearch}
          />
        </div>
        <section style={{ padding: 20 }}>
          <Gap height={5} />
          {/* Tournament berlangsung */}
          <TextCustom
            fontSize={16}
            fontWeight="bold"
            color={colors.black}
            text={t("LiveTournaments")}
          />
          <Gap height={20} />

          <Flex
            style={{
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {dataListTournamentLive?.length ? (
              dataListTournamentLive?.map((item, index) => {
                return (
                  <ItemListTournamentLive
                    item={item}
                    index={index}
                    id={item?.id_tournament}
                  />
                );
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </Flex>

          {/* Tournament Score */}
          <Gap height={20} />

          <TextCustom
            fontSize={16}
            color={colors.black}
            fontWeight="bold"
            text={t("ScoreTournament")}
          />
          <Gap height={5} />

          <Flex justify="space-between">
            <TextCustom
              fontSize={14}
              fontWeight="bold"
              color={colors.black}
              text="Bracket"
            />
            <TextCustom
              fontSize={14}
              color={colors.black}
              fontWeight="bold"
              text={t("SeeAll")}
              onClick={() =>
                navigate("/scoreboard", {
                  state: {
                    select: "Bracket",
                  },
                })
              }
            />
          </Flex>
          <Gap height={10} />
          <Flex
            style={{
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {dataBracket?.length ? (
              dataBracket?.slice(0, 5)?.map((item) => {
                return <ListItemScoreboard isHome={true} item={item} />;
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </Flex>

          <Gap height={15} />

          <Flex justify="space-between">
            <TextCustom
              fontSize={14}
              color={colors.black}
              fontWeight="bold"
              text={t("ScoreTournamentNonBraket")}
            />
            <TextCustom
              fontSize={14}
              color={colors.black}
              fontWeight="bold"
              text={t("SeeAll")}
              onClick={() =>
                navigate("/scoreboard", {
                  state: {
                    select: "Non Bracket",
                  },
                })
              }
            />
          </Flex>
          <Gap height={10} />
          <Flex
            style={{
              overflowX: "scroll",
              whiteSpace: "nowrap",
            }}
          >
            {dataNonBracket?.length ? (
              dataNonBracket?.slice(0, 5)?.map((item) => {
                return <ListItemScoreNonBracket item={item} isHome={true} />;
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </Flex>

          {/* Menu lainnya */}
          <Gap height={15} />

          <TextCustom
            fontSize={16}
            color={colors.black}
            fontWeight="bold"
            text={t("OtherMenus")}
          />
          <Gap height={10} />

          <Flex style={{}}>
            <Flex align="center" gap={"small"} justify="space-between">
              <Button
                onClick={() => {
                  navigate("/leaderboard");
                }}
                style={{
                  color: colors.white,
                  backgroundColor: colors.primary2,
                }}
                color={colors.primary1}
                fontWeight="bold"
                type="primary"
              >
                {t("Leaderboard")}
              </Button>
            </Flex>
          </Flex>

          {/* Daftar Tournament */}
          <Gap height={20} />

          <TextCustom
            fontSize={16}
            color={colors.black}
            fontWeight="bold"
            text={t("Alltournament")}
          />
          <Gap height={10} />
          <Flex
            vertical
            style={{
              width: "100%",
              overflowX: "scroll",
              height: "50vh",
            }}
          >
            {dataListTournament?.length ? (
              dataListTournament?.map((item, index) => {
                if (item?.status != "3")
                  return (
                    <ListItemTournament item={item} id={item?.id_tournament} />
                  );
              })
            ) : (
              <Flex
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextCustom fontSize={12} text={t("DataNotFound")} />
              </Flex>
            )}
          </Flex>
        </section>
        <Modal
          title={t("SearchTournament")}
          fontWeight="bold"
          open={isModalSearch}
          onCancel={() => setIsModalSearch(false)}
          footer={null}
          style={{
            overflowX: "scroll",
            height: "70%",
          }}
          // wrapStyle={{ height: window.innerHeight - 200,  }}
        >
          <Gap height={20} />
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ padding: 10 }}
            placeholder={t("SearchTournament")}
            allowClear
          />
          <Gap height={20} />

          <Flex
            vertical
            style={{
              width: "100%",
              overflowX: "scroll",
              height: "50vh",
            }}
          >
            <RenderListSearch />
          </Flex>
        </Modal>
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesRoomTournament;
