import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  message,
  Modal,
  Select,
  Spin,
} from "antd";
import React, { useState } from "react";
import edit from "../../../Assets/images/tournament/icon/edit.png";
import medali from "../../../Assets/images/tournament/icon/medali.png";
import arrowLeftWhite from "../../../Assets/images/tournament/icon/arrow-left-white.png";
import tournament from "../../../Assets/images/tournament/tournament.png";
import ButtonCustom from "../../../components/atoms/Button";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemPlayer from "../../../components/ListItemPlayer";
import colors from "../../../utils/colors";

import { useLocation, useNavigate } from "react-router-dom";
import "./index.scss";
import Constant from "../../../config/constans";
import axios from "axios";
import { useEffect } from "react";
import { uniq, values } from "lodash";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const LandingPagesDetailTeam = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectCaptain, setSelectCaptain] = useState("");
  const { dataState, isFromMyTeam } = useLocation()?.state;
  const authToken = localStorage.getItem("token");
  const idUser = localStorage.getItem("idUserLogin");
  const [messageApi, contextHolder] = message.useMessage();
  const [formChange] = Form.useForm();
  const [dataUser, setDataUser] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [dataDetailTeam, setDataDetailTeam] = useState({});
  const [deskripsiTeam, setDeskripsiTeam] = useState("");
  const [namaTeam, setNamaTeam] = useState("");
  const [options, setOptions] = useState([]);
  const [listRole, setListRole] = useState([]);
  console.log("DATASTATE LandingPagesDetailTeam", dataState, isFromMyTeam);
  const [loadingData, setLoadingData] = useState(false);
  const [userIsManager, setUserIsManager] = useState({});
  const [dataManagerRole, setDataManagerRole] = useState({});
  const [openModalCheckTeam, setOpenModalCheckTeam] = useState(false);
  const [dataModalCheckTeam, setDataModalCheckTeam] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    formChange.setFieldsValue({
      id_team: dataDetailTeam?.id_team,
      badger: dataDetailTeam?.badger,
      image_team: "-",
      deskripsi_team: dataDetailTeam?.deskripsi_team,
      nama_team: dataDetailTeam?.nama_team,
      id_kategori_game: dataDetailTeam?.id_kategori_game,
    });
  }, [dataDetailTeam]);

  const roleTeam = () => {
    let manager = listRole.find((item) => item.nama_role == "Manager");

    let isManager = dataDetailTeam?.teams?.find(
      (item) => item.role == manager?.id_role,
    );

    let captain = dataDetailTeam?.teams?.find(
      (item) => item?.id_user == dataDetailTeam?.captain,
    );

    console.log("managerr", manager);

    setDataManagerRole(manager);

    setUserIsManager(isManager);
    setSelectCaptain(captain?.id_user);
    formChange.setFieldsValue({
      captain: captain?.nama,
    });
  };

  const getListRole = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ROLE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          setListRole(data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  console.log("dataDetailTeam", dataDetailTeam);

  const handleEditTeam = () => {
    try {
      let values = formChange.getFieldsValue();
      setLoadingData(true);
      let tmpTeams = [];
      dataDetailTeam?.teams?.map((item) => {
        tmpTeams.push({
          id_user: item?.id_user,
        });
      });
      let tmpDetailUser = dataUser?.flag_team?.find(
        (item) => item?.id_team == dataDetailTeam?.id_team,
      );
      console.log("tmpDetailUser", tmpDetailUser);
      let payload = {
        id_team: dataDetailTeam?.id_team,
        badger: dataDetailTeam?.badger,
        image_team: "-",
        deskripsi_team: values?.deskripsi_team,
        nama_team: values?.nama_team,
        id_kategori_game: dataDetailTeam?.id_kategori_game,
        captain: selectCaptain,
        id_tournament: tmpDetailUser?.id_tournament,
        teams: uniq(tmpTeams),
        teams_deleted: [],
      };
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };
      const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_UPDATE_TEAM;
      axios
        .post(URL, payload, { headers })
        .then((response) => {
          if (response.status === 200) {
            let data = response.data.Data[0];
            setIsModalOpen(false);
            getDetailTeam();
          }
          setLoadingData(false);
        })
        .catch((error) => {
          setLoadingData(false);

          if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setTimeout(() => {
              navigate("/");
            }, 1000);
          } else {
            setTimeout(() => {
              messageApi.open({
                type: "error",
                content: error.message,
                duration: 4,
              });
            }, 1000);
          }
        });
    } catch (error) {
      console.log("eerrrr", error);
    }
  };

  const getDetailProfile = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_user: idUser,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_USER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataUser(response.data.Data[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const getDetailTeam = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_TEAM;
    axios
      .post(URL, { id_team: dataState?.id_team }, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          setDataDetailTeam(data);
          let tmp = [];
          data?.teams?.map((item) => {
            tmp.push({ value: item?.id_user, label: item?.nama });

            if (item?.id_user == data?.captain) {
              setSelectCaptain(item?.id_user);
              formChange.setFieldsValue({
                captain: item?.nama,
              });
            }
          });
          setOptions(uniq(tmp));
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleDeleteTeam = (record) => {
    let payload = {
      id_team: dataDetailTeam?.id_team,
      id_kategori_game: dataDetailTeam?.id_kategori_game,
      id_user_delete: record?.id_user,
    };
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    setLoadingData(true);
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DELETE_TEAM;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data[0];
          messageApi.open({
            type: "success",
            content: "Berhasil menghapus anggota team",
            duration: 4,
          });
          getDetailTeam();
        }
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleAddTournament = async () => {
    setIsModalOpen(false);
  };

  const itemlistCard = [
    {
      title: t("TotalTournament"),
      value: dataDetailTeam?.total_tournament,
    },
    {
      title: t("TotalWin"),
      value: dataDetailTeam?.total_menang,
    },
    {
      title: t("TotalLose"),
      value: dataDetailTeam?.total_kalah,
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const handleRegisterTeam = async () => {
    setLoadingData(true);
    let tmpTeams = [];
    dataDetailTeam?.teams.map((item) => {
      tmpTeams.push({ id_user: item?.id_user });
    });
    const payload = {
      image_team: "-",
      nama_team: dataDetailTeam?.nama_team,
      deskripsi_team: dataDetailTeam?.deskripsi_team,
      role: dataManagerRole?.id_role,
      teams: tmpTeams,
      id_kategori_game: dataDetailTeam?.id_kategori_game,
      id_tournament: dataState?.id_tournament,
      id_user: dataUser?.id_user,
      is_existing: true,
      id_team: dataDetailTeam?.id_team,
    };
    console.log("payloadd register", payload);

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_TOURNAMENT;
    await axios
      .post(URL, payload, { headers })
      .then((response) => {
        setLoadingData(false);

        if (response.status === 200) {
          setTimeout(() => {
            messageApi.open({
              type: "success",
              content: "Success Add Team",
              duration: 4,
            });
            navigate("/finish-register", {
              state: {
                dataState: response.data.Data[0],
                isSuccessRegister: true,
              },
            });
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: "Error Add Team",
              duration: 4,
            });
            navigate("/detail-team", {
              state: {
                dataState: response.data.Data[0],
                isSuccessRegister: false,
              },
            });
          }, 1000);
        }
      })
      .catch((error) => {
        setLoadingData(false);

        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const handleModalDelete = (item) => {
    setOpenModalCheckTeam(true);
    setDataModalCheckTeam(item);
    // handleDeleteTeam(item);
  };

  useEffect(() => {
    getDetailTeam();
    getDetailProfile();
    getListRole();
  }, []);

  useEffect(() => {
    roleTeam();
  }, [dataDetailTeam, listRole]);

  return (
    <Spin spinning={loadingData} tip="Loading...">
      <LayoutMobile>
        {contextHolder}

        <Layout
          className="mobile-first-layout"
          style={{
            width: "100%",
            backgroundColor: colors.white,
            position: "relative",
          }}
        >
          {/* Header */}
          <section style={{ position: "relative" }}>
            <section style={{ position: "relative", top: 0, zIndex: 8 }}>
              {/* <Image src={mobileLegendXL} style={{ width: "100%" }} /> */}
              <div
                className="card"
                style={{
                  background: "linear-gradient(to right, #9D50BB, #6E48AA)",
                  width: "100%",
                  height: 300,
                }}
              ></div>

              <Flex
                vertical
                style={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  padding: 20,
                  paddingRight: 40,
                }}
              >
                <Image
                  preview={false}
                  src={dataDetailTeam?.image_team}
                  style={{ borderRadius: 100, height: 100, width: 100 }}
                />
                <Gap height={10} />

                <Flex justify="space-between" align="center">
                  <Flex align="center">
                    <TextCustom
                      fontWeight="bold"
                      fontSize={14}
                      color={colors.white}
                      text={dataDetailTeam?.nama_team}
                    />
                    <Gap width={10} />
                    <Image
                      preview={false}
                      src={medali}
                      style={{ height: 20, width: 20 }}
                    />
                  </Flex>

                  {userIsManager?.name_role == "Manager" && isFromMyTeam && (
                    <Image
                      preview={false}
                      src={edit}
                      style={{ height: 30, width: 30 }}
                      onClick={showModal}
                    />
                  )}
                </Flex>
                <TextCustom
                  color={colors.white}
                  text={dataDetailTeam?.deskripsi_team}
                />
              </Flex>
            </section>
            <Flex
              justify="space-between"
              style={{
                padding: 20,
                position: "absolute",
                zIndex: 100,
                top: 0,
              }}
            >
              <Flex
                gap={"small"}
                align="center"
                style={{
                  backgroundColor: "transparent",
                  zIndex: 10,
                  position: "relative",
                }}
              >
                <Image
                  onClick={() => navigate(-1)}
                  preview={false}
                  width={20}
                  src={arrowLeftWhite}
                />

                <TextCustom
                  fontSize={16}
                  fontWeight="bold"
                  color={colors.white}
                  text={t("DetailTeam")}
                />
              </Flex>
              {/* <Image preview={false} width={30} src={search} /> */}
            </Flex>
          </section>

          <Layout
            style={{
              padding: 20,
              backgroundColor: colors.white,
            }}
          >
            <Flex
              style={{
                overflowX: "scroll",
                whiteSpace: "nowrap",
              }}
            >
              {itemlistCard.map((item, index) => {
                return (
                  <Flex
                    vertical
                    wrap
                    style={{
                      backgroundColor: colors.secondary1,
                      width: 300,
                      borderRadius: 10,
                      margin: 10,
                      marginLeft: index == 0 ? 0 : 10,
                    }}
                  >
                    <Flex vertical style={{ margin: 10 }}>
                      <TextCustom
                        fontSize={10}
                        color={colors.black}
                        fontWeight="bold"
                        text={item?.title}
                      />
                      <Flex vertical>
                        <Flex vertical>
                          <TextCustom
                            fontWeight="bold"
                            fontSize={20}
                            color={colors.primary2}
                            text={item?.value}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            <Gap height={20} />
            <TextCustom
              fontWeight="bold"
              fontSize={14}
              color={colors.black}
              text="Member Team"
            />

            <Gap height={20} />
            {dataDetailTeam?.teams?.map((item) => {
              return (
                <ListItemPlayer
                  item={item}
                  // type={
                  //   userIsManager?.name_role == "Manager" &&
                  //   (isFromMyTeam || dataState?.isRegister)
                  //     ? "trash"
                  //     : "mvp"
                  // }
                  type={
                    isFromMyTeam
                      ? item?.name_role == "Manager" && item?.id_user == idUser
                        ? ""
                        : "trash"
                      : ""
                  }
                  onClickIcon={() =>
                    userIsManager?.name_role == "Manager" &&
                    (isFromMyTeam || dataState?.isRegister)
                      ? handleModalDelete(item)
                      : false
                  }
                  role={
                    dataDetailTeam?.captain == item?.id_user
                      ? "Captain"
                      : item?.name_role
                  }
                />
              );
            })}

            <Gap height={50} />

            {dataState?.isRegister && userIsManager?.name_role == "Manager" && (
              <ButtonCustom
                onClick={() => handleRegisterTeam()}
                text={"Daftar"}
              />
            )}
          </Layout>
          <Modal
            onCancel={() => setIsModalOpen(false)}
            title="Edit Team"
            open={isModalOpen}
            footer={null}
          >
            <Gap height={30} />
            <Form
              form={formChange}
              onFinish={handleEditTeam}
              name="layout-multiple-vertical"
              layout="vertical"
              labelCol={{
                span: 100,
              }}
              wrapperCol={{
                span: 100,
              }}
            >
              <Form.Item
                label="Nama Team"
                name="nama_team"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Nama Team",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Deskripsi Team"
                name="deskripsi_team"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Deskripsi Team",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Pilih Captain"
                name="captain"
                rules={[
                  {
                    required: true,
                    message: "Silahkan Masukkan Captain Team",
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Pilih role"
                  onChange={(value, select) => {
                    setSelectCaptain(value);
                  }}
                >
                  {options
                    ? options.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))
                    : null}
                </Select>
                {/* <se
                  options={options}
                  optionRender={(e) => (
                    <div
                      onClick={() =>
                        setSelectCaptain({
                          id: e?.label,
                          value: e?.value,
                        })
                      }
                    >
                      {e?.value}
                    </div>
                  )}
                  placeholder="Pilih captain"
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                /> */}
              </Form.Item>
              <Gap height={20} />

              {userIsManager?.name_role == "Manager" && (
                <ButtonCustom htmlType={"submit"} text={"Submit"} />
              )}
            </Form>
          </Modal>
          <Modal
            title=""
            closable={false}
            open={openModalCheckTeam}
            // onCancel={() => setIsModalSearch(false)}
            footer={null}
            style={{
              overflowX: "scroll",
              height: "70%",
            }}
            // wrapStyle={{ height: window.innerHeight - 200,  }}
          >
            <Flex vertical style={{ textAlign: "center" }}>
              <Gap height={20} />
              <TextCustom
                fontWeight="bold"
                fontSize={14}
                color={colors.black}
                text={t("ApakahAndaYakinInginMengeluarkanDariTeamSkyTeam", {
                  name: dataModalCheckTeam?.nama,
                  team: dataDetailTeam?.nama_team,
                })}
              />
            </Flex>
            <Gap height={40} />
            <Flex gap={"large"}>
              <ButtonCustom
                onClick={() => handleDeleteTeam(dataModalCheckTeam)}
                text={t("Yes")}
                backgroundColor={colors.primary2}
              />
              <ButtonCustom
                onClick={() => setOpenModalCheckTeam(false)}
                text={t("No")}
                backgroundColor={colors.red}
              />
            </Flex>
          </Modal>
        </Layout>
      </LayoutMobile>
    </Spin>
  );
};

export default LandingPagesDetailTeam;
