import { Flex, Image } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import colors from "../../utils/colors";
import Gap from "../atoms/Gap";
import TextCustom from "../atoms/Text";
import "./index.scss";

export default function ListItemScoreboard({ item, isHome }) {
  console.log("item", item);
  const navigate = useNavigate();
  return (
    <Flex
      // onClick={() => navigate(`/room-tournament/${item?.id_tournament}`)}
      vertical
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
        width: !isHome ? "100%" : "70%",
        marginRight: !isHome ? 0 : 10,
      }}
    >
      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Flex vertical style={{ width: !isHome ? "100%" : 200 }}>
          <TextCustom
            fontSize={14}
            color={colors.black}
            fontWeight="bold"
            text={item?.name_tournament}
          />
          {!isHome && (
            <TextCustom
              fontSize={12}
              color={colors.black}
              text={item?.name_kategori}
            />
          )}
        </Flex>
        {!isHome && (
          <TextCustom
            fontSize={12}
            color={colors.black}
            text={moment(item?.createat).format("DD MMMM YYYY")}
          />
        )}
      </Flex>
      <Gap height={10} />

      <Flex align="center" justify="space-between" style={{ width: "100%" }}>
        <Image
          preview={true}
          src={item?.image_team_a}
          style={{
            borderRadius: !isHome ? 50 : 30,
            height: !isHome ? 50 : 30,
            width: !isHome ? 50 : 30,
          }}
        />
        <Flex vertical justify="center">
          {!isHome && (
            <TextCustom
              fontSize={14}
              color={colors.black}
              fontWeight="bold"
              text={item?.name_team_a}
              style={{ textAlign: "center" }}
            />
          )}
          <TextCustom
            fontSize={!isHome ? 18 : 14}
            color={colors.black}
            fontWeight="bold"
            text={item?.score_team_a}
            style={{ textAlign: "center" }}
          />
        </Flex>
        <TextCustom
          fontSize={!isHome ? 18 : 14}
          color={colors.black}
          fontWeight="bold"
          text="VS"
        />
        <Flex vertical justify="center">
          {!isHome && (
            <TextCustom
              fontSize={14}
              color={colors.black}
              fontWeight="bold"
              text={item?.name_team_b}
              style={{ textAlign: "center" }}
            />
          )}
          <TextCustom
            fontSize={!isHome ? 18 : 14}
            color={colors.black}
            fontWeight="bold"
            text={item?.score_team_b}
            style={{ textAlign: "center" }}
          />
        </Flex>
        <Image
          preview={true}
          src={item?.image_team_b}
          style={{
            borderRadius: !isHome ? 50 : 30,
            height: !isHome ? 50 : 30,
            width: !isHome ? 50 : 30,
          }}
        />
      </Flex>
    </Flex>
  );
}
