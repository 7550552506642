import { Flex, Image } from "antd";
import React from "react";
import activeMember from "../../Assets/images/tournament/icon/activeMember.png";
import add from "../../Assets/images/tournament/icon/add.png";
import mvp from "../../Assets/images/tournament/icon/mvp.png";
import trash from "../../Assets/images/tournament/icon/trash.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import TextCustom from "../../components/atoms/Text";
import colors from "../../utils/colors";
import "./index.scss";
import { useNavigate } from "react-router-dom";

// type = trash, add, role, player
export default function ItemListPlayer({
  item,
  type = "trash",
  onClickIcon,
  dataListTerdaftar,
  role,
}) {
  const navigate = useNavigate();
  const Icon = () => {
    switch (type) {
      case "mvp":
        return mvp;
      case "add":
        return add;
      case "player":
        return activeMember;
      case "trash":
        return trash;

      default:
        return trash;
    }
  };
  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
        border: `1px solid ${
          dataListTerdaftar && dataListTerdaftar.includes(item)
            ? colors.primary1
            : colors.secondary1
        }`,
      }}
    >
      <Flex
        onClick={() =>
          type == "add" || type == "trash"
            ? navigate("/detail-player", {
                state: {
                  dataState: item,
                },
              })
            : navigate("/detail-player", {
                state: {
                  dataState: item,
                },
              })
        }
        align="center"
        justify="space-between"
        gap={"small"}
      >
        <Image
          preview={true}
          src={item?.photo_profile}
          style={{ borderRadius: 50, height: 50, width: 50 }}
        />
        <Flex vertical>
          <TextCustom
            fontSize={12}
            fontWeight="bold"
            color={colors.black}
            text={item?.nama}
          />
          <TextCustom fontSize={12} color={colors.black} text={item?.email} />
        </Flex>
      </Flex>
      <Flex>
        {(type == "mvp" || type == "player" || role == "Captain") && (
          <div
            style={{
              backgroundColor: colors.primary1,
              position: "absolute",
              right: 0,
              top: 0,
              borderTopRightRadius: 10,
              borderBottomLeftRadius: 10,
              padding: 4,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <TextCustom fontSize={12} color={colors.white} text={role} />
          </div>
        )}
        {type && type !== "mvp" && (
          <Image
            preview={false}
            src={Icon()}
            onClick={onClickIcon}
            // onClick={() => onClickIcon(item)}
            style={{
              height: 25,
              width: 25,
              marginTop:
                type == "mvp" || type == "player" || role == "Captain" ? 20 : 0,
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}
