import React from "react";

export default function Gap({ width, height, backgroundColor }) {
  return (
    <div
      style={{
        height,
        width,
        backgroundColor: backgroundColor ?? "transparent",
      }}
    ></div>
  );
}
