import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Image,
  Input,
  Layout,
  Modal,
  Spin,
  Upload,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import addIc from "../../../Assets/images/tournament/icon/add.png";
import ButtonCustom from "../../../components/atoms/Button";
import Gap from "../../../components/atoms/Gap";
import TextCustom from "../../../components/atoms/Text";
import HeaderMobile from "../../../components/HeaderMobile";
import LayoutMobile from "../../../components/LayoutMobile";
import ListItemPlayer from "../../../components/ListItemPlayer";
import Constant from "../../../config/constans";
import colors from "../../../utils/colors";
import "./index.scss";
import { useTranslation } from "react-i18next";

const LandingPagesRegister = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formAdd] = Form.useForm();
  const authToken = localStorage.getItem("token");
  const idUser = localStorage.getItem("idUserLogin");
  const [loadingAddItem, setLoadingAddItem] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataListPlayer, setDataListPlayer] = useState([]);
  const [isModalSearch, setIsModalSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [dataListTerdaftar, setDataListTerdaftar] = useState([]);
  const [dataListTeamTerdaftar, setDataListTeamTerdaftar] = useState([]);
  const [options, setOptions] = useState([]);
  const [dataUser, setDataUser] = useState({});
  const [dataMaxMember, setDataMaxMember] = useState({});
  const [base64ImageAdd, setBase64ImageAdd] = useState("");
  const { dataState } = useLocation().state;
  const { t } = useTranslation();
  console.log("object", dataState);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleManager, setSelectedRoleManager] = useState("");
  const showModal = () => {
    setIsModalOpen(true);
  };
  // const options = [{ value: "Manager" }, { value: "Player" }];
  const handleAddTournament = async () => {
    setIsModalOpen(false);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const [fileNameAddUploadItem, setFileNameAddUploadItem] = useState("");
  const [informationFile, setInformationFile] = useState(null);

  const beforeUpload = (file) => {
    const MAX_FILE_SIZE = 0.6;

    setInformationFile(file);
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    if (!isLt600KB) {
      message.error(`File harus lebih kecil dari 600KB!`);
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64ImageAdd(reader.result);
    };

    const fileName = file.name;
    setFileNameAddUploadItem(fileName);

    return false;
  };

  const handleChange = (info) => {
    console.log("handleChange", info);
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);

    const filteredData = dataListPlayer.filter((record) => {
      return Object.values(record).some(
        (fieldValue) =>
          fieldValue &&
          fieldValue.toString().toLowerCase().includes(value.toLowerCase()),
      );
    });

    setFilteredDataSource(filteredData);
  };

  const getListPlayer = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_USER;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          let tmp = [];
          console.log("data", data);
          data?.map((item) => {
            if (item?.flag_team?.length) {
              item?.flag_team.map((sort) => {
                if (
                  sort?.id_kategori_game == dataState?.id_kategori_game &&
                  sort?.status_daftar == false &&
                  item?.id_user != idUser
                ) {
                  tmp.push(item);
                }
              });
            } else {
              tmp.push(item);
            }
          });
          console.log("daataa", tmp);

          setDataListPlayer(tmp);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getListRole = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_GET_LISTS_ROLE;
    axios
      .get(URL, { headers })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.Data;
          let tmp = [];
          data.map((item) => {
            tmp.push({ value: item?.nama_role, label: item?.id_role });
            console.log("asdfasdfa", item);
            if (item.nama_role == "Manager") {
              setSelectedRole({
                value: item?.nama_role,
                id: item?.id_role,
              });
              setSelectedRoleManager({
                value: item?.nama_role,
                id: item?.id_role,
              });

              formAdd.setFieldsValue({
                role: item?.nama_role,
              });
            }
          });

          setOptions(tmp);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleModalSearch = () => {
    setIsModalSearch(!isModalSearch);
  };

  const onFinish = (payload) => {
    const values = formAdd.getFieldsValue();

    console.log("onFinish", values);

    const regexUpload = /^data:image\/(\w+);base64,/;
    if (base64ImageAdd) {
      const matchUpload = base64ImageAdd.match(regexUpload);
      const imageWithoutPrefix = base64ImageAdd.substring(
        matchUpload[0].length,
      );
      values.image_team = imageWithoutPrefix;
    }
    values.teams = [...dataListTeamTerdaftar];
    values.id_kategori_game = dataState?.id_kategori_game;
    values.id_tournament = dataState?.id_tournament;
    values.id_user = dataUser?.id_user;
    values.role = selectedRoleManager?.id;
    values.is_existing = false;

    setLoadingAddItem(true);
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_ADD_TOURNAMENT;
    axios
      .post(URL, values, { headers })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            setLoadingAddItem(false);

            messageApi.open({
              type: "success",
              content: "Success Add Team",
              duration: 4,
            });
            formAdd.resetFields();
            navigate("/finish-register", {
              state: {
                dataState: response.data.Data[0],
                isSuccessRegister: true,
              },
            });
          }, 1000);
        } else {
          setTimeout(() => {
            setLoadingAddItem(false);

            messageApi.open({
              type: "error",
              content: "Error Add Team",
              duration: 4,
            });
            formAdd.resetFields();
            navigate("/detail-team", {
              state: {
                dataState: response.data.Data[0],
                isSuccessRegister: false,
              },
            });
          }, 1000);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };

  const getMaximalMemberByGame = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MAX_PESERTA_TOURNAMENT;
    axios
      .post(URL, { id_kategori_game: dataState?.id_kategori_game }, { headers })
      .then((response) => {
        if (response.status === 200 && response?.data?.Data) {
          setDataMaxMember(response?.data?.Data[0] || { max_member: 0 });
        } else {
          setDataMaxMember({ max_member: 0 });
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };

  const getMa = (payload) => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const URL =
      Constant.URL_MASTER_PATH + Constant.URL_GET_LIST_MAX_PESERTA_TOURNAMENT;
    axios
      .post(URL, { id_kategori_game: dataState?.id_kategori_game }, { headers })
      .then((response) => {
        if (response.status === 200) {
          setDataMaxMember(response.data.Data[0]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
            setLoadingAddItem(false);
            formAdd.resetFields();
          }, 1000);
        }
      });
  };

  const onClickIconAdd = (dataItem) => {
    const exists = dataListTerdaftar.some(
      (item) => item.id_user === dataItem.id_user,
    );

    if (dataListTerdaftar.length + 1 > parseInt(dataMaxMember?.max_member)) {
      messageApi.open({
        type: "error",
        content:
          "Maximal member yang dapat ditambahkan hanya " +
          dataMaxMember?.max_member,
        duration: 4,
      });
      return false;
    }
    if (!exists) {
      setDataListTerdaftar([...dataListTerdaftar, dataItem]);
      setDataListTeamTerdaftar([
        ...dataListTeamTerdaftar,
        {
          id_user: dataItem?.id_user,
        },
      ]);
    } else {
      setDataListTerdaftar(
        dataListTerdaftar.filter((item) => item.id_user !== dataItem.id_user),
      );
      setDataListTeamTerdaftar(
        dataListTeamTerdaftar.filter(
          (item) => item.id_user !== dataItem.id_user,
        ),
      );
    }
  };

  const onClickIconTrash = (dataItem) => {
    if (dataUser.id_user == dataItem.id_user) {
      messageApi.open({
        type: "error",
        content: "Tidak dapat menghapus data anda sebagai yang terdaftar",
        duration: 4,
      });
    } else {
      setDataListTerdaftar(
        dataListTerdaftar.filter((item) => item.id_user !== dataItem.id_user),
      );
      setDataListTeamTerdaftar(
        dataListTeamTerdaftar.filter(
          (item) => item.id_user !== dataItem.id_user,
        ),
      );
    }
  };

  const getDetailProfile = () => {
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const payload = {
      id_user: idUser,
    };
    const URL = Constant.URL_MASTER_PATH + Constant.URL_POST_DETAIL_USER;
    axios
      .post(URL, payload, { headers })
      .then((response) => {
        if (response.data.Code === "0") {
          setDataUser(response.data.Data[0]);
          setDataListTerdaftar([response.data.Data[0]]);
          setDataListTeamTerdaftar([
            {
              id_user: response.data.Data[0].id_user,
            },
          ]);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error.code === "ERR_NETWORK") {
          messageApi.open({
            type: "error",
            content: error.message,
            duration: 4,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: error.message,
              duration: 4,
            });
          }, 1000);
        }
      });
  };
  const MAX_FILE_SIZE = 0.6;

  const checkFileSize = (file) => {
    if (!file || !file.size) {
      return true;
    }
    const isLt600KB = file.size / 1024 / 1024 < MAX_FILE_SIZE;
    return isLt600KB;
  };

  const fileValidator = (_, value) => {
    console.log("value", value);
    const isFileValid = checkFileSize(informationFile);
    if (!value || value.length === 0) {
      return Promise.reject(new Error(t("UploadYourTeamLogo")));
    }
    if (!isFileValid) {
      return Promise.reject(new Error(`File harus lebih kecil dari 600KB!`));
    }

    return Promise.resolve();
  };
  console.log("dataState", dataState);

  useEffect(() => {
    getListPlayer();
  }, []);

  useEffect(() => {
    getDetailProfile();
    getListRole();
    getMaximalMemberByGame();
  }, [dataState]);

  const RenderListSearch = () => {
    if (searchText) {
      return filteredDataSource.map((item, index) => {
        return (
          <ListItemPlayer
            dataListTerdaftar={dataListTerdaftar}
            item={item}
            key={index}
            type="add"
            onClickIcon={() => onClickIconAdd(item)}
          />
        );
      });
    }
    return dataListPlayer.map((item, index) => {
      return (
        <ListItemPlayer
          dataListTerdaftar={dataListTerdaftar}
          item={item}
          key={index}
          type="add"
          onClickIcon={() => onClickIconAdd(item)}
        />
      );
    });
  };

  return (
    <LayoutMobile>
      {contextHolder}
      {/* Header */}
      <Layout
        className="mobile-first-layout"
        style={{
          width: "100%",
          backgroundColor: colors.white,
          position: "relative",
          padding: 20,
        }}
      >
        <HeaderMobile title={t("RegisterTournaments")} isSearch={false} />
        <Gap height={15} />

        <TextCustom
          fontSize={16}
          color={colors.black}
          text={t("YourTeamData")}
        />
        <Gap height={10} />
        <TextCustom
          fontSize={12}
          color={colors.black}
          text={t("PleaseFillInTheDataAccordingToTheFormNeeded")}
        />
        <Gap height={20} />
        <Spin spinning={loadingAddItem} tip="Loading...">
          <Form
            name="layout-multiple-vertical"
            layout="vertical"
            onFinish={onFinish}
            form={formAdd}
          >
            <TextCustom
              fontSize={12}
              color={colors.black}
              text={t("PleaseChooseYourLogoTeam")}
            />
            <Gap height={10} />
            <Form.Item
              rules={[
                {
                  validator: fileValidator,
                },
              ]}
              label=""
              name="image_team"
            >
              <Upload
                accept="image/png, image/jpeg"
                maxCount={1}
                listType="picture-card"
                beforeUpload={beforeUpload}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
            <Gap height={20} />

            <Form.Item
              label={t("TeamName")}
              name="nama_team"
              rules={[
                {
                  required: true,
                  message: t("EnterNameTeam"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("TeamDescription")}
              name="deskripsi_team"
              rules={[
                {
                  required: true,
                  message: t("EnterDescTeam"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("RolePostition")}
              name="role"
              rules={[
                {
                  required: true,
                  message: t("EnterRoleTeam"),
                },
              ]}
            >
              <AutoComplete
                options={options}
                placeholder={t("SelectRole")}
                optionRender={(e) => (
                  <div
                    onClick={() =>
                      setSelectedRole({ id: e.label, value: e.value })
                    }
                  >
                    {e?.value}
                  </div>
                )}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>

            <Gap height={20} />

            <TextCustom
              fontWeight="bold"
              fontSize={14}
              color={colors.black}
              text={t("Participants")}
            />
            <Gap height={20} />
            {dataListTerdaftar.map((item) => {
              return (
                <ListItemPlayer
                  item={item}
                  type={item?.id_user == idUser ? "" : "trash"}
                  onClickIcon={() => onClickIconTrash(item)}
                />
              );
            })}
            <Gap height={50} />
            <Flex justify="center">
              <Image
                onClick={handleModalSearch}
                preview={false}
                src={addIc}
                style={{ height: 50, width: 50 }}
              />
            </Flex>
            <Gap height={50} />

            <ButtonCustom
              htmlType="submit"
              // onClick={() => navigate("/detail-team")}
              text={t("Save")}
            />
          </Form>
          <Gap height={50} />
        </Spin>
        <Modal
          title={t("AddParticipant")}
          open={isModalSearch}
          onCancel={() => setIsModalSearch(false)}
          footer={null}
          style={{
            overflowX: "scroll",
            height: "70%",
          }}
          // wrapStyle={{ height: window.innerHeight - 200,  }}
        >
          <Gap height={20} />
          <Input
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ padding: 10 }}
            placeholder={t("SearchParticipants")}
            allowClear
          />
          <Gap height={20} />
          <Flex
            vertical
            style={{
              width: "100%",
              overflowX: "scroll",
              height: "50vh",
            }}
          >
            <RenderListSearch />
          </Flex>

          <Gap height={20} />
          {/* <Button
            variant="contained"
            onClick={handleAddTournament}
            style={{
              borderRadius: 100,
              backgroundColor: colors.primary2,
              color: colors.white,
              padding: 20,
              width: "100%",
            }}
          >
            Tambah
          </Button> */}
        </Modal>
      </Layout>
    </LayoutMobile>
  );
};

export default LandingPagesRegister;
