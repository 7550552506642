import { Flex, Image } from "antd";
import React from "react";
import star from "../../Assets/images/tournament/icon/star.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import colors from "../../utils/colors";
import TextCustom from "../atoms/Text";
import "./index.scss";

export default function ListItemLeaderboard({ item, image, nama, point }) {
  return (
    <Flex
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        marginBottom: 10,
        position: "relative",
        width: "100%",
      }}
    >
      <Flex align="center" justify="space-between" gap={"small"}>
        <Image
          preview={true}
          src={image}
          style={{ borderRadius: 50, height: 50, width: 50 }}
        />
        <Flex vertical>
          <TextCustom
            fontSize={14}
            fontWeight="bold"
            color={colors.black}
            text={nama}
          />
          {/* <Flex gap={"small"}>
            <TextCustom
              fontSize={12}
              color={colors.secondary2}
              text={`Menang : ${point}`}
            />
            <TextCustom
              fontSize={12}
              color={colors.secondary2}
              text="Kalah : 0"
            />
          </Flex> */}
        </Flex>
      </Flex>
      <Flex align="center">
        <Image
          preview={false}
          src={star}
          style={{
            height: 20,
            width: 20,
          }}
        />
        <TextCustom fontSize={14} color={colors.primary1} text={point} />
      </Flex>
    </Flex>
  );
}
