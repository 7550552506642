import { Flex, Image } from "antd";
import React from "react";
import people from "../../Assets/images/tournament/icon/people.png";
import cup from "../../Assets/images/tournament/icon/cup.png";
import notif from "../../Assets/images/tournament/icon/notif.png";
import mobileLegend from "../../Assets/images/tournament/mobile-legend.png";
import TextCustom from "../../components/atoms/Text";
import colors from "../../utils/colors";
import { Navigate, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import convertToRupiah from "../../utils/convertToRupiah.js";

export default function ListItemTournament({ item, id, index }) {
  const navigate = useNavigate();
  const statusTournaments = [
    {
      label: "Dibuka",
      value: "1",
    },
    {
      label: "Full",
      value: "2",
    },
    {
      label: "Tutup",
      value: "3",
    },
  ];

  const filterStatusFilter = () => {
    let data = statusTournaments.filter((e) => {
      return item.status == e.value;
    });
    return data[0]?.label;
  };

  return (
    <Flex
      style={{
        marginBottom: 10,
        marginTop: 10,
        backgroundColor: colors.secondary1,
        padding: 10,
        borderRadius: 10,
        position: "relative",
        marginRight: index == 0 ? 0 : 10,
        width: "100%",
      }}
    >
      <Flex
        gap={"small"}
        justify="space-between"
        align="center"
        style={{ position: "relative" }}
      >
        <Image
          src={item?.banner}
          style={{
            borderRadius: 10,
            width: 100,
            height: 100,
            objectFit: "cover",
          }}
        />
        <Flex
          vertical
          style={{ marginTop: -20 }}
          onClick={() =>
            navigate(`/room-tournament/${id}`, {
              state: {
                dataState: item,
              },
            })
          }
        >
          <TextCustom fontSize={12} text={item?.nama_kategori_game} />

          <TextCustom
            fontWeight="bold"
            fontSize={12}
            color={colors.black}
            text={item?.nama_tournament}
          />
          <TextCustom
            text={`${item?.nama_kategori_game} |  ${dayjs(
              item?.start_date,
            ).format("DD MMMM")} -  ${dayjs(item?.end_date).format(
              "DD MMMM YYYY",
            )}`}
          ></TextCustom>
          <Flex
            gap="small"
            style={{ position: "absolute", bottom: 0, width: "100%" }}
          >
            <Flex align="center" gap={2} justify="center">
              <Image preview={false} width={15} src={people} />
              <TextCustom fontSize={10} text={item?.total_peserta} />
            </Flex>
            <Flex align="center" gap={2} justify="center">
              <Image preview={false} width={15} src={cup} />
              <TextCustom
                fontSize={10}
                text={convertToRupiah(item?.registration_fee)}
              />
            </Flex>
            <Flex align="center" gap={2} justify="center">
              <Image preview={false} width={15} src={notif} />
              <TextCustom fontSize={10} text={filterStatusFilter()} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {item?.status == 1 && (
        <div
          style={{
            backgroundColor: colors.primary1,
            position: "absolute",
            right: 0,
            top: 0,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: 10,
            padding: 4,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <TextCustom fontSize={12} color={colors.white} text="Daftar" />
        </div>
      )}
    </Flex>
  );
}
